import React, { useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";

function HelpButton() {
  const { t } = useTranslation("homepage");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        type='primary'
        shape='circle'
        icon={<AiOutlineQuestionCircle size={28} />}
        onClick={showModal}
        style={{
          position: "fixed",
          bottom: "96px",
          right: "16px",
          width: "50px",
          height: "50px",
        }}
        className='z-20 text-white help-btn-ok'
      />
      <Modal
        title='Help'
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Close'
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{
          className: "help-btn-ok",
        }}>
        <p>
          {t("homepage:contactMessage")}{" "}
          <a
            href='https://wa.me/46729208253'
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue-500 hover:text-blue-400 hover:underline'>
            {t("homepage:whatsappMessage")}
          </a>
        </p>
      </Modal>
    </>
  );
}

export default HelpButton;
