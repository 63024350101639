import React from 'react';
import { Link } from 'react-router-dom';
import { HiOutlineArrowLongLeft } from 'react-icons/hi2';

const PageSubheader = ({ backLink, title, description }) => {
  return (
    <header className="flex items-center justify-between bg-white w-full drop-shadow mb-10">
      <Link to={backLink} className="flex inline-flex back-btn mx-4">
        <HiOutlineArrowLongLeft size={30} color="white" />
      </Link>
      <article className="w-full flex-col items-center justify-center mr-16 my-6">
        <h1 className="text-xl font-bold flex-grow text-center">
          {title}
        </h1>
        <p className="mt-1 text-sm font-semibold text-center text-gray-500">
          {description}
        </p>
      </article>
    </header>
  );
};

export default PageSubheader;