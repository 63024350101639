import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getReq, putReq } from "../../configs/ApiUrl";
import { useTranslation } from "react-i18next";
import { FaTruck } from "react-icons/fa";
import { LoadingSpinner } from "./util/LoadingSpinner";
import PageSubheader from "../PageSubheader.js";
import AlwaysTop from "./util/AlwaysTop.js";

function DeliveryNeeded() {
  const { t } = useTranslation(["admin", "subadmin", "tables", "altText"]);
  const [loading, setLoading] = useState(false);
  const [preapprovedProducts, setPreapprovedProducts] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const [historyProducts, setHistoryProducts] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [subadminEmail, setSubadminEmail] = useState("");
  const [courier, setCourier] = useState("");
  const navigate = useNavigate();

  const fetchUserRole = async () => {
    try {
      // First, fetch the user profile to get the user ID
      const userProfileResp = await getReq("/user/profile");
      const userId = userProfileResp?.user?._id;
  
      if (!userId) {
        console.error("User ID not found in profile response");
        return;
      }
  
      // Then, use the user ID to get the user role
      const roleResp = await getReq(`/user/${userId}`);
      if (roleResp?.success) {
        const { role, email, companyName } = roleResp;
        setUserRole(role);
  
        // If the user is a subadmin, set additional subadmin-specific details
        if (role === "subadmin") {
          setSubadminEmail(email);
          setCourier(companyName);
        }
      } else {
        console.error("Failed to fetch user role:", roleResp.message);
      }
    } catch (error) {
      console.error("Error fetching user role:", error.message);
    }
  };

  const getAllHistoryProducts = async () => {
    try {
      setLoading(true);
      const pendingProductsResp = await getReq("/product/history");
      if (pendingProductsResp?.success) {
        const filteredProducts = pendingProductsResp.historyProducts.filter(
          (product) => product.status !== "declined"
        );
        setHistoryProducts(filteredProducts);
      } else {
        setHistoryProducts([]);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate(userRole === "admin" ? "/admin-menu" : "/subadmin-menu");
      }
    } finally {
      setLoading(false);
    }
  };

  const getPreapprovedProducts = async () => {
    try {
      setLoading(true);
      const productsResp = await getReq("/product/all");
      if (productsResp?.allProducts) {
        const preapprovedProducts = productsResp.allProducts.filter(
          (product) => product.status === "preApproved"
        );
        setPreapprovedProducts(preapprovedProducts);
      } else {
        setPreapprovedProducts([]);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate(userRole === "admin" ? "/admin-menu" : "/subadmin-menu");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (id) => {
    try {
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: true,
      }));

      if (userRole === "subadmin") {
        const updateEmailResp = await putReq(`/product/update-subadmin-email`, {
          id,
          subadminEmail,
          courier,
        });
        if (!updateEmailResp?.success) {
          throw new Error("Failed to update subadmin email and courier name");
        }
      }

      const updatedProductResp = await putReq(`/product/status`, {
        id,
        status: "approved",
      });

      if (updatedProductResp?.success) {
        setLoadingStates((prevState) => ({
          ...prevState,
          [id]: false,
        }));
        await getPreapprovedProducts();
        await getAllHistoryProducts();
      } else {
        setLoadingStates((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }
    } catch (error) {
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    }
  };

  useEffect(() => {
    AlwaysTop();
    fetchUserRole(); // Fetch user role on component mount
  }, []);

  useEffect(() => {
    if (userRole) {
      getAllHistoryProducts();
      getPreapprovedProducts();
    }
  }, [userRole]);

  return (
    <div className="min-h-screen">
      <PageSubheader
        backLink={userRole === "admin" ? "/admin-menu" : "/subadmin-menu"}
        title={t("admin:deliveryNeededButton")}
        description={t("admin:deliveryNeededExplainer")}
      />

      <main className="flex flex-col items-center justify-center">
        <div className="overflow-x-auto mt-6">
          {loading ? (
            <LoadingSpinner />
          ) : preapprovedProducts.length > 0 ? (
            <table className="table-auto border-collapse bg-white">
              <thead>
                <tr>
                  <th className="px-4 py-2 bg-none border-b-4 border-cromboOrangeFade text-black font-semibold">
                    {t("admin:item")}
                  </th>
                  <th className="px-4 border-b-4 border-cromboOrangeFade text-black font-semibold">
                    {t("admin:deliveryAmountHeader")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {preapprovedProducts.map((product, index) => (
                  <tr key={index}>
                    <td className="relative border-b-4 border-cromboOrangeFade">
                      {product?.imageNames && product?.imageNames.length > 0 ? (
                        <img
                          className="w-full max-w-md h-auto"
                          src={product.imageNames[0]}
                          alt={t("altText:firstImage")}
                        />
                      ) : (
                        <div>No image available</div>
                      )}
                      <div className="table-overlay">
                        <span className="truncate text-sm">
                          {product.title.length > 20
                            ? product.title.substring(0, 15) + "..."
                            : product.title}
                        </span>
                      </div>
                    </td>
                    <td className="px-4 py-2 border-b-4 border-cromboOrangeFade text-center text-md font-bold">
                      {product?.deliveryPrice >= 0 ? product?.deliveryPrice : "0"} kr.
                      <button
                        className="flex items-center justify-center p-4 mt-2 rounded-lg bg-white border-2 border-white text-cromboOrange whitespace-nowrap hover:text-cromboOrangeFade cursor-pointer drop-shadow"
                        onClick={() => handleApprove(product._id)}
                      >
                        <FaTruck className="h-8 w-8 mr-2" />
                        {userRole === "admin" ? t("subadmin:removeDeliveryReq") : t("subadmin:deliveryFulfil")}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="flex items-center justify-center w-96 h-96 bg-white">
              <h1 className="text-xl font-bold text-center text-gray-500">
                {t("admin:noProducts")}
              </h1>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}

export default DeliveryNeeded;
