import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageHeader from "../PageHeader";
import { AdminContext } from "./util/AdminContext";
import { FaTruck, FaTruckLoading, FaPlusSquare, FaLongArrowAltLeft } from "react-icons/fa";
import { postReq, getReq } from "../../configs/ApiUrl.js";
import AlwaysTop from "./util/AlwaysTop";

function SubadminMenu() {
    const { t } = useTranslation(['admin', 'subadmin', 'altText', 'userProfile']);
    const navigate = useNavigate();
    const { setIsAdmin } = useContext(AdminContext);
    const [loading, setLoading] = useState(false);
    const [welcomeMessage, setWelcomeMessage] = useState('');

    const handleAdminAccess = () => {
        setIsAdmin(true);
    };

    const handleLogOut = async () => {
        try {
            setLoading(true);
            const logOutResp = await postReq('/user/logout');
            if (logOutResp?.success) {
                setLoading(false);
                localStorage.removeItem('userId');
                navigate('/welcome-login');
            }
        } catch (error) {
            console.error("Logout failed:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        AlwaysTop();

        const fetchUserData = async () => {
            try {
                const userId = localStorage.getItem("userId");
                if (!userId) throw new Error("User ID not found in localStorage");

                const response = await getReq(`/user/${userId}`);
                if (response?.success) {
                    const { companyName, username } = response;
                    if (response && response.companyName) {
                        setName(response.companyName || response.username);
                    }                } else {
                    console.error("Failed to fetch user data");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [t]);

    return (
        <div className="flex flex-col items-center">
            <div className="px-4 sm:px-8 md:px-16 lg:px-24 bg-white w-full">
                <header className="flex flex-col items-center justify-center mt-4 p-6 rounded-b-2xl font-bold text-lg bg-white shadow-md w-full">
                    <PageHeader />
                    <h1 className="mt-12 text-3xl font-bold text-center">{t('admin:menuTitle')}</h1>
                    <h3 className="text-gray-500">{t('subadmin:welcome')} {welcomeMessage}</h3>
                    <img alt="Horizontal divider" src="./../assets/icons/divider-horizontal.svg" className="w-40 sm:w-60 mt-2" />
                </header>
                <main className="p-6 w-full">
                    {[
                        { to: "/subadmin-product-status", icon: "/assets/icons/bed-icon.svg", alt: t('altText:bed'), label: t('subadmin:productStatusButton') },
                        { to: "/subadmin-add-product", icon: <FaPlusSquare className="h-14 w-14 mr-2 text-white" />, alt: t('altText:bed'), label: t('admin:addProductButton') },
                        { to: "/live-listings", icon: "/assets/icons/shop.svg", alt: t("altText:liveListings"), label: t('admin:liveButton') },
                        { to: "/delivery-needed", icon: <FaTruck className="h-14 w-14 mr-2 text-white" />, alt: t('altText:whiteDivider'), label: t('admin:deliveryNeededButton') },
                        { to: "/active-orders", icon: "/assets/icons/sell-icon.svg", alt: t('altText:dollar'), label: t("admin:activeButton") },
                        { to: "/delivery-status", icon: <FaTruckLoading className="h-14 w-14 mr-2 text-white" />, alt: t('altText:whiteDivider'), label: t('admin:deliveryStatusButton') },
                        { to: "/products-history", icon: "/assets/icons/order-history.svg", alt: t('altText:orderHistory'), label: t('admin:historyButton') },
                        { to: "/discount-codes", icon: "/assets/icons/percent.svg", alt: t('altText:percentage'), label: t('admin:discountButton') },
                        { to: "/user-profile", icon: <FaLongArrowAltLeft className="h-14 w-14 mr-2 text-white" />, alt: t('altText:backArrow'), label: t('subadmin:mainSite') }
                    ].map((link, index) => (
                        <div key={index} className="flex justify-center my-6">
                            <Link to={link.to} className="w-full max-w-lg">
                                <button className="bg-cromboOrange rounded-2xl p-4 sm:p-6 flex flex-col items-center justify-center hover:bg-cromboOrangeFade w-full">
                                    {typeof link.icon === "string" ? <img className="mb-4 h-14 w-14" src={link.icon} alt={link.alt} /> : link.icon}
                                    <h2 className="text-white text-lg sm:text-2xl text-center font-semibold">{link.label}</h2>
                                    <img className="mt-4" src="/assets/icons/divider-white.svg" alt={link.alt} width={80} />
                                </button>
                            </Link>
                        </div>
                    ))}
                    <div className="flex justify-center mt-3 mb-10 w-full">
                        <button onClick={handleLogOut} className={`btn-danger flex items-center justify-center mb-8 w-full max-w-lg ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={loading}>
                            {loading ? <span>Loading...</span> : <img src="/assets/icons/logout.svg" alt={t('altText:logout')} width={40} />}
                            <h2 className="text-white text-lg sm:text-2xl text-center font-semibold">{t('userProfile:logoutButton')}</h2>
                        </button>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default SubadminMenu;
