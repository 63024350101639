import React, { useState, useContext } from "react";
import dayjs from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { generateDate, months, slots } from "./util/calendar";
import { cn } from "./util/cn";
import { FurnitureSearch } from "../../App";
import { postReq } from "../../configs/ApiUrl";
import { message } from "antd";
import { useTranslation } from "react-i18next";

dayjs.extend(isSameOrBefore);

function Calendar() {
    const { t } = useTranslation(['calendarPage']);
    const [searchTerm, setSearchTerm] = useContext(FurnitureSearch);
    const [today, setToday] = useState(dayjs());
    const [selectDate, setSelectDate] = useState(dayjs());
    const [chosenDate, setChosenDate] = useState(null);
    const [time, setTime] = useState({
        slots: slots,
        buttonBackground: Array(slots.length).fill("bg-stone-300 text-gray-400"),
        selected: Array(slots.length).fill(false),
    });
    const [selectedTimes, setSelectedTimes] = useState({
        selectedDates: [],
        selectedSlots: [],
    });
    const [errorWarn, setErrorWarn] = useState('');
    const [next, setNext] = useState({
        button: "bg-stone-300 text-gray-400",
    });
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Extract `isSeller`, `grandTotal`, and `shoppingCart` from the state
    const { isSeller, grandTotal, shoppingCart } = location.state || {};

    // Function to handle date selection
    function handleDateClick(date) {
        if (date.isSameOrBefore(dayjs(), 'day')) {
            alert(t('pastDateAlert'));
            return;
        }
        setSelectDate(date);
        setChosenDate({
            day: date.date(),
            month: date.month() + 1,
            year: date.year(),
        });
        setTime({
            ...time,
            buttonBackground: Array(slots.length).fill("bg-stone-400 text-black"),
            selected: Array(slots.length).fill(false),
        });
    }

    const formatDateAndTime = (dateString, timeString) => {
        const [day, month, year] = dateString.split('.');
        const date = new Date(year, month - 1, day);
        return {
            date: date,
            time: timeString.trim()
        };
    };

    // Function to handle time slot selection
    function timeSlotBoolean(index) {
        const newSelected = [...time.selected];
        newSelected[index] = !newSelected[index];
        setTime({
            ...time,
            selected: newSelected,
            buttonBackground: newSelected.map(selected =>
                selected ? "bg-cromboOrange text-white" : "bg-stone-400 text-black"
            ),
        });

        const selectedDate = `${chosenDate.day}.${chosenDate.month}.${chosenDate.year}`;
        const currentSlots = time.slots.filter((_, i) => newSelected[i]).join(', ');

        const datesAdded = [...selectedTimes.selectedDates];
        const slotsAdded = [...selectedTimes.selectedSlots];
        const dateIndex = datesAdded.indexOf(selectedDate);
        if (currentSlots) {
            if (dateIndex !== -1) {
                slotsAdded[dateIndex] = currentSlots;
            } else {
                datesAdded.push(selectedDate);
                slotsAdded.push(currentSlots);
            }
        } else if (dateIndex !== -1) {
            datesAdded.splice(dateIndex, 1);
            slotsAdded.splice(dateIndex, 1);
        }

        setSelectedTimes({ selectedDates: datesAdded, selectedSlots: slotsAdded });
        setNext({
            button: datesAdded.length >= 3 ? "bg-cromboOrange text-white" : "bg-stone-300 text-gray-400",
        });
    }

    // Function to handle the main button click
    const handleButtonClick = async () => {
        if (selectedTimes.selectedDates.length < 3) {
            setErrorWarn(t('minimumThree'));
            return;
        }

        const selectedTimeDateSlots = selectedTimes.selectedDates.map((date, index) => {
            return formatDateAndTime(date, selectedTimes.selectedSlots[index]);
        });

        if (isSeller) {
            // Seller-specific logic
            try {
                setIsLoading(true);
                if (selectedTimeDateSlots?.length) {
                    selectedTimeDateSlots.forEach((slot, index) => {
                        searchTerm?.productFormData.append(`pickUpSlots[${index}][date]`, slot.date);
                        searchTerm?.productFormData.append(`pickUpSlots[${index}][time]`, slot.time);
                    });
                }

                const productResp = await postReq("/product/add", searchTerm?.productFormData);
                if (productResp?.success) {
                    message.success(t('uploadSuccess'), 2);
                    setSearchTerm({
                        ...searchTerm,
                        productFormData: {},
                    });
                    navigate('/selling-add-success');
                } else {
                    message.error(t('uploadFailed'), 1);
                }
            } catch (error) {
                message.error(t('uploadFailed'), 1);
            } finally {
                setIsLoading(false);
            }
        } else {
            // Catalogue-specific logic
            setSearchTerm({
                ...searchTerm,
                deliverySlots: selectedTimeDateSlots,
            });
            navigate('/checkout', {
                state: {
                    grandTotal,
                    shoppingCart,
                    deliverySlots: selectedTimeDateSlots,
                },
            });
        }
    };

    return (
        <div className="flex flex-col items-center bg-stone-200 h-full">
            {/* Title */}
            <div>
                <h1 className="px-5 pt-10 text-xl font-bold pb-3">{t('title')}</h1>
                <h1 className="px-5 font-semibold pb-5 text-stone-600">{t('subTitle')}</h1>
            </div>

            {/* Calendar display */}
            <div className="flex w-10/12 mx-auto items-center justify-center max-w-md bg-white rounded-xl pt-3 pb-10 px-3">
                <div className="w-96 h-96">
                    <div className="flex justify-between">
                        <h1 className="font-semibold">{months[today.month()]}, {today.year()}</h1>
                        <div className="flex items-center gap-5">
                            <GrFormPrevious className="w-5 h-5 cursor-pointer" onClick={() => {
                                if (today.month(today.month() - 1).$M < dayjs().$M && today.month(today.month() - 1).$y <= dayjs().$y) {
                                    return;
                                }
                                setToday(today.month(today.month() - 1));
                            }} />
                            <h1 className="cursor-pointer" onClick={() => setToday(dayjs())}>
                                Today
                            </h1>
                            <GrFormNext className="w-5 h-5 cursor-pointer" onClick={() => setToday(today.month(today.month() + 1))} />
                        </div>
                    </div>

                    <div className="w-full grid grid-cols-7 text-gray-500">
                        {["S", "M", "T", t('Wednesday'), "T", "F", t('Saturday')].map((day, index) => (
                            <h1 key={index} className="h-14 grid place-content-center text-sm">{day}</h1>
                        ))}
                    </div>

                    <div className="w-full grid grid-cols-7">
                        {generateDate(today.month(), today.year()).map(({ date, currentMonth, today }, index) => (
                            <div key={index} className="h-14 border-t grid place-content-center text-sm">
                                <h1 className={cn(
                                    currentMonth ? "" : "text-gray-400",
                                    today ? "bg-gray-500 text-white" : "",
                                    selectDate.toDate().toDateString() === date.toDate().toDateString() ? "bg-cromboOrange text-white" : "",
                                    "h-10 w-10 grid place-content-center rounded-full transition-all cursor-pointer"
                                )} onClick={() => handleDateClick(date)}>
                                    {date.date()}
                                </h1>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Time slots */}
            <div>
                <h1 className="pt-4 text-xl font-bold pb-3">{t('slots')}</h1>
            </div>
            <div className="grid grid-cols-3">
                {time.slots.map((slot, index) => (
                    <div key={index} className={`${time.buttonBackground[index]} text-sm w-22 m-2 text-center p-2 rounded-xl font-semibold cursor-pointer`}
                        onClick={() => {
                            if (!chosenDate) {
                                alert(t('noDateAlert'));
                                return;
                            }
                            timeSlotBoolean(index);
                        }}>
                        {slot}
                    </div>
                ))}
            </div>

            {/* Chosen time slots */}
            <div>
                <h1 className="pt-2 text-xl font-bold pb-3 mt-4">{t('chosenSlots')}</h1>
            </div>
            <div>
                {selectedTimes.selectedDates.map((item, i) => (
                    <div key={i} className="bg-cromboOrange flex rounded-xl w-60 mb-2 cursor-pointer p-2 text-white text-3xl items-center">
                        <div className="flex flex-col items-center justify-center text-base w-52">
                            <div className="text-stone-600 font-bold">{item}</div>
                            <div className="text-white font-semibold">{selectedTimes.selectedSlots[i]}</div>
                        </div>
                        <div className="pr-3" onClick={() => removeTime(i)}>
                            <IoMdClose size={40} />
                        </div>
                    </div>
                ))}
            </div>

            {/* Main button */}
            <div className="pt-2 pb-5" onClick={handleButtonClick}>
                <div className={`${next.button} rounded-5xl mt-6 flex items-center justify-center mb-2 ${isLoading ? 'opacity-50 pointer-events-none' : ''}`}>
                    {isLoading ? (
                        <h1 className="text-white text-lg text-center font-semibold">Loading...</h1>
                    ) : (
                        <h1 className="text-white text-lg text-center font-semibold">{isSeller ? t('uploadButton') : t('proceedButton')}</h1>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Calendar;
