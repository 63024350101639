import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import MoblerHeader from "../MoblerHeader.js";
import { postReq } from "../../configs/ApiUrl.js";
import { useTranslation } from "react-i18next";
import AlwaysTop from "./util/AlwaysTop.js";
import InputField from "../InputField.js";
import AuthContext from "./util/AuthContext.js";

function WelcomeLogin() {
  const { t } = useTranslation(["forms", "altText", "subadmin"]);
  const { login } = useContext(AuthContext); // Destructure the login function from AuthContext
  const navigateHomePage = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [emailState, setEmailState] = useState({
    emailBackground: "bg-white",
    emailHolder: t("emailLabel"),
  });

  const [passwordState, setPasswordState] = useState({
    passwordBackground: "bg-white",
    passwordHolder: t("passwordLabel"),
  });

  const [loginError, setLoginError] = useState("");

  useEffect(() => {
    setEmailState({
      emailBackground: "bg-white",
      emailHolder: t("emailLabel"),
    });

    setPasswordState({
      passwordBackground: "bg-white",
      passwordHolder: t("passwordLabel"),
    });
  }, [t]);

  useEffect(() => {
    AlwaysTop();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    if (!email || !password) {
      setIsLoading(false);
      if (!email) {
        setEmailState({ ...emailState, emailBackground: "bg-red-200" });
      }
      if (!password) {
        setPasswordState({
          ...passwordState,
          passwordBackground: "bg-red-200",
        });
      }
      return;
    }

    try {
      setIsLoading(true);
      const userLoginData = { email, password };
      const loginResponse = await postReq("/user/login", userLoginData);

      if (loginResponse?.token) {
        login(loginResponse.user, loginResponse.token);
        navigateHomePage("/shopping-home");
      } else {
        setLoginError("Incorrect email or password.");
      }
    } catch (error) {
      console.error("Internal server error at logging in:", error);
      setLoginError("An error occurred while logging in.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='flex flex-col items-center min-h-screen bg-background'>
      <div className='w-full bg-cromboGray'>
        <MoblerHeader />
      </div>
      <main className='flex flex-col items-center w-full max-w-md px-6'>
        <div className='flex items-center pt-10 mb-4 w-full'>
          <img
            alt={t("altText:verticalDivider")}
            src='./../assets/icons/divider-vertical.svg'
            className='h-10 mr-4'
          />
          <h1 className='text-3xl font-bold'>{t("login")}</h1>
        </div>
        <form
          className='flex flex-col items-center w-full'
          onSubmit={handleSubmit}>
          <div className='w-full'>
            <InputField
              label={t("emailLabel")}
              name='email'
              value={emailState.value}
              className={`${emailState.emailBackground} px-4 py-2`}
              placeholder={emailState.emailHolder}
              onClick={() => {
                setEmailState({
                  ...emailState,
                  emailBackground: "bg-white",
                  emailHolder: t("emailPlace"),
                });
                setLoginError("");
              }}
              onChange={(e) =>
                setEmailState({ ...emailState, value: e.target.value })
              }
            />

            <InputField
              label={t("passwordLabel")}
              type='password'
              name='password'
              value={passwordState.value}
              className={`${passwordState.passwordBackground} px-4 py-2`}
              placeholder={passwordState.passwordHolder}
              onClick={() => {
                setPasswordState({
                  ...passwordState,
                  passwordBackground: "bg-white",
                  passwordHolder: t("passwordPlace"),
                });
                setLoginError("");
              }}
              onChange={(e) =>
                setPasswordState({ ...passwordState, value: e.target.value })
              }
            />
          </div>

          {loginError && (
            <p className='text-red-500 text-sm mt-2'>{loginError}</p>
          )}

          <div className='w-full mb-6 flex items-center'>
            <input
              className='hover:cursor-pointer'
              type='checkbox'></input>
            <label className='pl-2'>{t("rememberCheck")}</label>
          </div>

          <button
            className={`btn-main w-full flex items-center justify-center mb-8 ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isLoading}>
            {isLoading ? (
              <span>Loading...</span>
            ) : (
              <span className='text-white text-lg lg:text-2xl text-center font-semibold'>
                {t("login")}
              </span>
            )}
          </button>
        </form>

        <a
          href='shopping-home'
          className='text-cromboOrange font-bold mb-10 hover:cursor-pointer hover:underline'>
          {t("noAccount")}
        </a>

        <h2 className='flex items-center text-xl font-semibold mb-12 mt-12'>
          {t("signUpPrompt")}
          <Link to='/welcome-signup'>
            <h2 className='text-cromboOrange pl-1 font-bold hover:cursor-pointer hover:underline'>
              {t("signUp")}
            </h2>
          </Link>
        </h2>
      </main>
    </div>
  );
}

export default WelcomeLogin;
