import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  HiOutlinePencil,
  HiOutlineTrash,
  HiOutlineArrowLongLeft,
  HiPlus,
} from "react-icons/hi2";
import { LoadingSpinner } from "./util/LoadingSpinner";
import DiscountEditModal from "./util/DiscountEditModal";
import AddDiscountModal from "./util/AddDiscountModal";
import DeleteWarningModal from "./util/DeleteWarningModal";
import { useTranslation } from "react-i18next";
import { getReq, putReq, postReq, delReq } from "../../configs/ApiUrl";
import PageSubheader from "../PageSubheader";
import AlwaysTop from "./util/AlwaysTop";

function DiscountCodes() {
  const { t } = useTranslation(["admin", "altText"]);
  const [loading, setLoading] = useState(true);
  const [flatRateData, setFlatRateData] = useState([]);
  const [percentageData, setPercentageData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [currentCode, setCurrentCode] = useState("");
  const [currentValue, setCurrentValue] = useState("");
  const [addType, setAddType] = useState(""); // Track the type of code being added
  const [errorMessage, setErrorMessage] = useState(""); // Track error messages
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDiscountId, setSelectedDiscountId] = useState(null);
  const [deleteType, setDeleteType] = useState(""); // Track the type of code being deleted
  const [userRole, setUserRole] = useState("");
  const [userEmail, setUserEmail] = useState(""); // Track the user email

  const fetchUserRole = async () => {
    try {
      // First, fetch the user profile to get the user ID
      const userProfileResp = await getReq("/user/profile");
      const userId = userProfileResp?.user?._id;
  
      if (!userId) {
        console.error("User ID not found in profile response");
        return;
      }
  
      // Then, use the user ID to get the user role
      const roleResp = await getReq(`/user/${userId}`);
      if (roleResp?.success) {
        const { role, email, companyName } = roleResp;
        setUserRole(role);
  
        // If the user is a subadmin, set additional subadmin-specific details
        if (role === "subadmin") {
          setSubadminEmail(email);
          setCourier(companyName);
        }
      } else {
        console.error("Failed to fetch user role:", roleResp.message);
      }
    } catch (error) {
      console.error("Error fetching user role:", error.message);
    }
  };

  const fetchFlatRateCodes = async () => {
    try {
      const response = await getReq("/discountCodes/flatRateCodes");
      if (response?.success) {
        setFlatRateData(
          response.flatRateCodes
            .filter(
              (item) =>
                (userRole === "admin" || item.author === userEmail) &&
                !item.deleted
            )
            .map((item) => ({
              _id: item._id,
              code: item.code || item.examplePercent,
              value: item.value,
            }))
        );
      } else {
        console.error("Failed to fetch flat rate codes:", response);
        setFlatRateData([]);
      }
    } catch (error) {
      console.error("Error fetching flat rate codes:", error);
    }
  };

  const fetchPercentageCodes = async () => {
    try {
      const response = await getReq("/discountCodes/percentageCodes");
      if (response?.success) {
        setPercentageData(
          response.percentageCodes
            .filter(
              (item) =>
                (userRole === "admin" || item.author === userEmail) &&
                !item.deleted
            )
            .map((item) => ({
              _id: item._id,
              code: item.code || item.examplePercent,
              value: item.value,
            }))
        );
      } else {
        console.error("Failed to fetch percentage codes:", response);
        setPercentageData([]);
      }
    } catch (error) {
      console.error("Error fetching percentage codes:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserRole();
      setLoading(true);
      await Promise.all([fetchFlatRateCodes(), fetchPercentageCodes()]);
      setLoading(false);
    };
    fetchData();
  }, [userRole]);

  useEffect(() => {
    AlwaysTop();
  }, []);

  const handleEditClick = (id, code, value) => {
    setCurrentId(id);
    setCurrentCode(code);
    setCurrentValue(value);
    setIsModalOpen(true);
  };

  const handleSave = async (newCode, newValue) => {
    const isFlatRate =
      flatRateData.find((item) => item._id === currentId) !== undefined;
    const endpoint = isFlatRate
      ? `/discountCodes/flatrateCodes/${currentId}`
      : `/discountCodes/percentageCodes/${currentId}`;
    const data = { code: newCode, value: newValue };

    try {
      const response = await putReq(endpoint, data);
      if (response?.success) {
        if (isFlatRate) {
          setFlatRateData((prevData) =>
            prevData.map((item) =>
              item._id === currentId
                ? { ...item, code: newCode, value: newValue }
                : item
            )
          );
        } else {
          setPercentageData((prevData) =>
            prevData.map((item) =>
              item._id === currentId
                ? { ...item, code: newCode, value: newValue }
                : item
            )
          );
        }
      } else {
        console.error("Failed to update code:", response);
      }
    } catch (error) {
      console.error("Error updating code:", error);
    } finally {
      setLoading(false);
      setIsModalOpen(false);
    }
  };

  const handleAddClick = (type) => {
    setAddType(type);
    setErrorMessage(""); // Clear any existing error message
    setIsAddModalOpen(true);
  };

  const handleAddSave = async (newCode, newValue, type) => {
    const endpoint =
      type === "flatRate"
        ? `/discountCodes/flatRateCodes`
        : `/discountCodes/percentageCodes`;
    const data = { code: newCode, value: newValue, author: "Crombo" };

    try {
      const response = await postReq(endpoint, data);
      if (response?.success) {
        if (type === "flatRate") {
          setFlatRateData((prevData) => [...prevData, response.newCode]);
        } else {
          setPercentageData((prevData) => [...prevData, response.newCode]);
        }
        setIsAddModalOpen(false);
      } else {
        console.error("Failed to add code:", response);
      }
    } catch (error) {
      console.error("Error adding code:", error);
    }
  };

  const handleDelete = async (id, type) => {
    const endpoint =
      type === "flatRate"
        ? `/discountCodes/flatRateCodes/${id}`
        : `/discountCodes/percentageCodes/${id}`;

    try {
      setLoading(true);
      const response = await delReq(endpoint);
      if (response?.success) {
        if (type === "flatRate") {
          setFlatRateData((prevData) =>
            prevData.filter((item) => item._id !== id)
          );
        } else {
          setPercentageData((prevData) =>
            prevData.filter((item) => item._id !== id)
          );
        }
      } else {
        console.error("Failed to delete discount code:", response);
      }
    } catch (error) {
      console.error("Error deleting discount code:", error);
    } finally {
      setLoading(false);
      closeDeleteModal();
    }
  };

  const openDeleteModal = (id, type) => {
    setSelectedDiscountId(id);
    setDeleteType(type);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedDiscountId(null);
    setDeleteType("");
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    if (selectedDiscountId) {
      await handleDelete(selectedDiscountId, deleteType);
      closeDeleteModal();
    }
  };

  return (
    <div className='flex flex-col items-center'>
      <PageSubheader
        backLink={userRole === "admin" ? "/admin-menu" : "/subadmin-menu"}
        title={t("admin:discountButton")}
        description={t("admin:discountExplainer")}
      />
      {showDeleteModal && (
        <DeleteWarningModal
          onConfirm={handleConfirmDelete}
          onClose={closeDeleteModal}
        />
      )}

      <main className='flex flex-col justify-center mb-10 p-4 bg-white w-full drop-shadow'>
        <div className='overflow-x-auto'>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <h2 className='text-2xl font-bold my-4'>
                {t("admin:flatHeader")}
              </h2>
              <table className='table-auto w-full border-collapse rounded-t-xl -p-1 text-center'>
                <thead>
                  <tr>
                    <th className='px-4 py-2 bg-none border-b-4 border-cromboOrangeFade text-black font-semibold'>
                      {t("admin:code")}
                    </th>
                    <th className='px-4 border-b-4 border-cromboOrangeFade text-black font-semibold'>
                      {t("admin:value")}
                    </th>
                    <th className='px-4 border-b-4 border-cromboOrangeFade text-black font-semibold'>
                      {t("admin:actionHeader")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {flatRateData.map((item) => (
                    <tr
                      className='hover:bg-gray-50'
                      key={item._id}>
                      <td className='px-4 py-2 border'>{item.code}</td>
                      <td className='px-4 py-2 border'>{item.value} kr.</td>
                      <td className='flex justify-center px-4 py-2 border'>
                        <div
                          onClick={() =>
                            handleEditClick(item._id, item.code, item.value)
                          }
                          className='flex items-center justify-center mr-8 pr-4 rounded-lg bg-white border-8 border-white text-cromboOrange hover:cromboOrangeFade cursor-pointer drop-shadow'>
                          <HiOutlinePencil className='inline-block mx-2 cursor-pointer h-8 w-8' />
                          <h4 className='ml-2 font-bold pointer-events-none'>
                            {t("editButton")}
                          </h4>
                        </div>
                        <div
                          className='flex items-center justify-center pr-4 rounded-lg bg-white border-8 border-white text-red-400 hover:text-red-600 cursor-pointer drop-shadow'
                          onClick={() => openDeleteModal(item._id, "flatRate")}>
                          <HiOutlineTrash className='inline-block mx-2 cursor-pointer h-8 w-8' />
                          <h4 className='ml-2 font-bold'>
                            {t("deleteButton")}
                          </h4>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <tr className='hover:bg-gray-50'>
                    <td className='px-4 py-2 border-1 flex justify-center items-center'>
                      <button
                        className='flex items-center text-cromboOrange font-bold'
                        onClick={() => handleAddClick("flatRate")}>
                        <HiPlus
                          className='mr-2 h-6 w-6'
                          alt={t("altText:plus")}
                        />
                        {t("admin:newCodeButton")}
                      </button>
                    </td>
                    <td className='px-4 py-2'></td>
                    <td className='px-4 py-2'></td>
                  </tr>
                </tbody>
              </table>

              <h2 className='text-2xl font-bold my-4 mt-16'>
                {t("admin:percentageHeader")}
              </h2>
              <table className='table-auto w-full border-collapse rounded-t-xl -p-1 mb-4 text-center'>
                <thead>
                  <tr>
                    <th className='px-4 py-2 bg-none border-b-4 border-cromboOrangeFade text-black font-semibold'>
                      {t("admin:code")}
                    </th>
                    <th className='px-4 border-b-4 border-cromboOrangeFade text-black font-semibold'>
                      {t("admin:value")}
                    </th>
                    <th className='px-4 border-b-4 border-cromboOrangeFade text-black font-semibold'>
                      {t("admin:actionHeader")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {percentageData.map((item) => (
                    <tr
                      className='hover:bg-gray-50'
                      key={item._id}>
                      <td className='px-4 py-2 border'>{item.code}</td>
                      <td className='px-4 py-2 border'>{item.value}%</td>
                      <td className='flex justify-center px-4 py-2 border'>
                        <div className='flex items-center justify-center mr-8 pr-4 rounded-lg bg-white border-8 border-white text-cromboOrange hover:cromboOrangeFade cursor-pointer drop-shadow'>
                          <HiOutlinePencil
                            className='inline-block mx-2 cursor-pointer h-8 w-8'
                            onClick={() =>
                              handleEditClick(item._id, item.code, item.value)
                            }
                          />
                          <h4 className='ml-2 font-bold pointer-events-none'>
                            {t("editButton")}
                          </h4>
                        </div>
                        <div
                          className='flex items-center justify-center pr-4 rounded-lg bg-white border-8 border-white text-red-400 hover:text-red-600 cursor-pointer drop-shadow'
                          onClick={() =>
                            openDeleteModal(item._id, "percentage")
                          }>
                          <HiOutlineTrash className='inline-block mx-2 cursor-pointer h-8 w-8' />
                          <h4 className='ml-2 font-bold'>
                            {t("deleteButton")}
                          </h4>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <tr className='hover:bg-gray-50'>
                    <td className='px-4 py-2 flex justify-center items-center'>
                      <button
                        className='flex items-center text-cromboOrange font-bold'
                        onClick={() => handleAddClick("percentage")}>
                        <HiPlus
                          className='mr-2 h-6 w-6'
                          alt={t("altText:plus")}
                        />
                        {t("admin:newCodeButton")}
                      </button>
                    </td>
                    <td className='px-4 py-2'></td>
                    <td className='px-4 py-2'></td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      </main>

      <DiscountEditModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setErrorMessage(""); // Clear the error message when closing the modal
        }}
        code={currentCode}
        value={currentValue}
        onSave={handleSave}
        flatRateData={flatRateData} // Pass the existing flat rate data
        percentageData={percentageData} // Pass the existing percentage data
        errorMessage={errorMessage} // Pass the error message to the modal
        setErrorMessage={setErrorMessage} // Pass the setter function to the modal
      />

      <AddDiscountModal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          setErrorMessage(""); // Clear the error message when closing the modal
        }}
        onSave={handleAddSave}
        type={addType} // Pass the type to the modal
        flatRateData={flatRateData} // Pass the existing flat rate data
        percentageData={percentageData} // Pass the existing percentage data
        errorMessage={errorMessage} // Pass the error message to the modal
        setErrorMessage={setErrorMessage} // Pass the setter function to the modal
      />
    </div>
  );
}

export default DiscountCodes;
