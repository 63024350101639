import defaultImg from "../../../images/defaultImg.webp";
import { useTranslation } from "react-i18next";
import productBG from "../../../images/productBG.jpg";

function OrderCard(props) {
  const { t } = useTranslation([
    "admin",
    "productPage",
    "utilityPages",
    "altText",
  ]);
  const {
    name,
    image,
    quantity,
    productCost,
    movingCost,
    extras,
    extraQuantity,
    extraCost,
    extraTotal,
    grandTotal,
    discount,
    bonusDiscount,
  } = props;

  return (
    <article className="flex-col justify-center mx-4 mt-3 mb-8 bg-white drop-shadow-lg rounded-lg relative">
      <div
        className="w-full h-40 overflow-hidden bg-cromboGray rounded-t-lg"
        style={{
          backgroundImage: `url(${productBG})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <img
          alt={name}
          src={image}
          className="w-full h-full object-contain"
          onError={(e) => {
            e.target.src = defaultImg;
          }}
        />
      </div>
      <div className="p-4 flex justify-between">
        <div className="font-bold text-wrap">{name}</div>
        <div className="ml-8 flex-col">
          <div className="flex items-center justify-between mb-1">
            <div className="text-sm mr-2 font-semibold -mb-1 whitespace-nowrap">
              {t("admin:price")}
            </div>
            <div className="text-lg font-bold whitespace-nowrap">
              {productCost ? <div>{productCost} kr.</div> : null}
            </div>
          </div>
          <div className="flex items-center justify-between mb-1">
            <div className="text-sm mr-2 font-semibold -mb-1 whitespace-nowrap">
              {t("productPage:deliveryLabel")}
            </div>
            <div className="text-lg font-bold whitespace-nowrap">
              {movingCost ? <div>{movingCost} kr.</div> : null}
            </div>
          </div>
          {extras?.map((extra, i) => (
            <div className="flex items-center justify-between mb-1" key={i}>
              <div className="text-sm mr-2 font-semibold -mb-1 whitespace-nowrap">
                {extra.quantity} x {extra.item}
              </div>
              <div className="text-lg font-bold whitespace-nowrap">
                {extra.cost} kr.
              </div>
            </div>
          ))}
          <div className="flex items-center justify-between mb-1">
            <div className="text-sm mr-2 font-semibold -mb-1 whitespace-nowrap">
              {t("utilityPages:addonTotal")}
            </div>
            <div className="text-lg font-bold whitespace-nowrap">
              <div>{extraTotal + " kr."}</div>
            </div>
          </div>
          <div className="flex items-center justify-between mb-1">
            <div className="text-sm mr-2 font-semibold -mb-1 whitespace-nowrap">
              {t("utilityPages:bonusDiscount")}
            </div>
            <div className="text-lg font-bold whitespace-nowrap">
              {bonusDiscount > 0 && <div>{bonusDiscount + " kr."}</div>}
            </div>
          </div>
          <img
            alt={t("horizontalDivider")}
            src="./../assets/icons/divider-horizontal.svg"
            className="w-25"
          />
          <div className="flex items-center justify-between mb-1">
            <div className="text-md mr-2 font-bold -mb-1 whitespace-nowrap">
              {t("admin:grandTotalHeader")}
            </div>
            <div className="text-xl text-cromboOrange font-bold whitespace-nowrap">
              <div>{grandTotal + " kr."}</div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default OrderCard;