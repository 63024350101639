import React, { Suspense, createContext, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
  Link,
} from "react-router-dom";
import AuthContext, {
  AuthProvider,
} from "./components/pages/util/AuthContext.js";
import { MdOutlineShoppingCart } from "react-icons/md";
import WelcomeLogin from "./components/pages/WelcomeLogin";
import WelcomeSignup from "./components/pages/WelcomeSignup";
import WelcomeSignupConfirmation from "./components/pages/WelcomeSignupConfirmation";
import WelcomeEmailValidated from "./components/pages/WelcomeEmailValidated";
import ShoppingHome from "./components/pages/ShoppingHome";
import RoomSearch from "./components/pages/RoomSearch.js";
import CategorySearch from "./components/pages/CategorySearch.js";
import CatalogueProduct from "./components/pages/ProductPage.js";
import SearchResults from "./components/pages/SearchResults";
import CatalogueCart from "./components/pages/ShoppingCart.js";
import Calendar from "./components/pages/Calendar.js";
import Checkout from "./components/pages/Checkout.js";
import database from "./components/pages/database.json";
import CatalogueFavourites from "./components/pages/UserFavourites.js";
import SellingAddFurniture from "./components/pages/UserAddProduct.js";
import AdminProductTable from "./components/pages/AdminProductTable";
import AdminProductView from "./components/pages/AdminProductView";
import Admin from "./components/pages/Admin.js";
import AdminMenu from "./components/pages/AdminMenu.js";
import UserProfile from "./components/pages/UserProfile.js";
import UserContact from "./components/pages/UserContact.js";
import UserBuy from "./components/pages/UserBuy.js";
import UserSell from "./components/pages/UserSell.js";
import ProductSuccess from "../src/screens/ProductSuccess.js";
import AdminAddProduct from "./components/pages/AdminAddProduct.js";
import AdminRegisterNew from "./components/pages/AdminRegisterNew.js";
import { OrderModal } from "./components/pages/util/OrderModal.js";
import ApprovedDelivery from "./components/pages/ApprovedDelivery.js";
import { AdminProvider } from "./components/pages/util/AdminContext.js";
import SubadminMenu from "./components/pages/SubadminMenu.js";
import SubadminProductStatus from "./components/pages/SubadminProductStatus.js";
import SubadminAddProduct from "./components/pages/SubadminAddProduct.js";
import SubadminProductSubmitted from "./components/pages/SubadminProductSubmitted.js";
import SubadminEditApproval from "./components/pages/SubadminEditApproval.js";
import { ProductProvider } from "./components/pages/util/ProductContext.js";
import KlarnaPaymentConfirmation from "./components/pages/util/KlarnaPaymentConfirmation.js";
import { OrderProvider } from "./components/pages/util/OrderContext.js";
import LiveListings from "./components/pages/LiveListings.js";
import DeliveryNeeded from "./components/pages/DeliveryNeeded.js";
import ActiveOrders from "./components/pages/ActiveOrders.js";
import DeliveryStatus from "./components/pages/DeliveryStatus.js";
import ProductsHistory from "./components/pages/ProductsHistory.js";
import DiscountCodes from "./components/pages/DiscountCodes.js";
import i18n from "./i18n.js";

export const FurnitureSearch = createContext();

function App() {
  const [searchTerm, setSearchTerm] = useState({
    displayName: "Sofas",
    searchFilter: database.filter((x) => x.type === "sofa"),
    productDisplay: {
      main: "visible",
      zoom: "hidden",
    },
    arrowNavigation: {
      default: "/shopping-home",
      productArrow: "visible",
      generalArrow: "hidden",
    },
    searchID: "",
    favourites: [],
    cartIcon: shoppingCart(),
    shoppingCart: [],
    productFormData: {},
    deliverySlots: [],
    loggedIn: false,
    checkoutIdentifier: "",
    adminSearch: database.filter((x) => x.id === 1),
  });

  function shoppingCart() {
    return (
      <div>
        <Link to='/catalogue-cart'>
          <MdOutlineShoppingCart size={25} />
        </Link>
      </div>
    );
  }

  const ProtectedRoute = ({ redirectPath = "/welcome-login" }) => {
    const { auth } = useContext(AuthContext);

    if (auth.isAuthenticated === false && !auth.user) {
      return <Navigate to={redirectPath} />;
    }

    return <Outlet />;
  };

  const ProtectedAdminRoute = () => {
    const { auth } = useContext(AuthContext);

    if (!auth.isAuthenticated || auth.user.role !== "admin") {
      return <Navigate to='/admin' />;
    }

    return <Outlet />;
  };

  const ProtectedSubadminRoute = () => {
    const { auth } = useContext(AuthContext);

    if (!auth.isAuthenticated || auth.user.role !== "subadmin") {
      return <Navigate to='/welcome-login' />;
    }

    return <Outlet />;
  };

  return (
    <AuthProvider>
      <FurnitureSearch.Provider value={[searchTerm, setSearchTerm]}>
        <AdminProvider>
          <ProductProvider>
            <OrderProvider>
              <Router>
                <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                    {/* Public routes */}
                    <Route
                      path='/'
                      element={<WelcomeLogin />}
                    />
                    <Route
                      path='/welcome-login'
                      element={<WelcomeLogin />}
                    />
                    <Route
                      path='welcome-signup'
                      element={<WelcomeSignup />}
                    />
                    <Route
                      path='signup-confirmation'
                      element={<WelcomeSignupConfirmation />}
                    />
                    <Route
                      path='welcome-email-validated'
                      element={<WelcomeEmailValidated />}
                    />
                    <Route
                      path='/shopping-home'
                      element={<ShoppingHome />}
                    />
                    <Route
                      path='/room-search/:room'
                      element={<RoomSearch />}
                    />
                    <Route
                      path='/category-search/:category'
                      element={<CategorySearch />}
                    />
                    <Route
                      path='/search-results/:query'
                      element={<SearchResults />}
                    />
                    <Route
                      path='/catalogue-product/:productId'
                      element={<CatalogueProduct />}
                    />

                    {/* Public Admin/Subadmin Routes */}
                    <Route
                      path='live-listings'
                      element={<LiveListings />}
                    />
                    <Route
                      path='delivery-needed'
                      element={<DeliveryNeeded />}
                    />
                    <Route
                      path='active-orders'
                      element={<ActiveOrders />}
                    />
                    <Route
                      path='delivery-status'
                      element={<DeliveryStatus />}
                    />
                    <Route
                      path='products-history'
                      element={<ProductsHistory />}
                    />
                    <Route
                      path='discount-codes'
                      element={<DiscountCodes />}
                    />

                    {/* Protected routes */}
                    <Route element={<ProtectedRoute />}>
                      <Route
                        path='user-profile'
                        element={<UserProfile />}
                      />
                      <Route
                        path='user-contact'
                        element={<UserContact />}
                      />
                      <Route
                        path='user-buy'
                        element={<UserBuy />}
                      />
                      <Route
                        path='user-sell'
                        element={<UserSell />}
                      />
                      <Route
                        path='catalogue-cart'
                        element={<CatalogueCart />}
                      />
                      <Route
                        path='checkout'
                        element={<Checkout />}
                      />
                      <Route
                        path='calendar'
                        element={<Calendar />}
                      />
                      <Route
                        path='order-success'
                        element={<OrderModal />}
                      />
                      <Route
                        path='order-success-klarna'
                        element={<KlarnaPaymentConfirmation />}
                      />
                      <Route
                        path='catalogue-favourites'
                        element={<CatalogueFavourites />}
                      />
                      <Route
                        path='selling-add-furniture'
                        element={<SellingAddFurniture />}
                      />
                      <Route
                        path='selling-add-success'
                        element={<ProductSuccess />}
                      />
                    </Route>

                    {/* Protected Admin Routes */}
                    <Route element={<ProtectedAdminRoute />}>
                      <Route
                        path='admin-add-product'
                        element={<AdminAddProduct />}
                      />
                      <Route
                        path='admin-product-table'
                        element={<AdminProductTable />}
                      />
                      <Route
                        path='admin-register-new'
                        element={<AdminRegisterNew />}
                      />
                      <Route
                        path='admin-menu'
                        element={<AdminMenu />}
                      />
                    </Route>

                    {/* Not a protected route as it is needed by subadmins */}
                    <Route
                      path='admin-product-view'
                      element={<AdminProductView />}
                    />

                    {/* Protected Subadmin Routes */}
                    <Route element={<ProtectedSubadminRoute />}>
                      <Route
                        path='subadmin-menu'
                        element={<SubadminMenu />}
                      />
                      <Route
                        path='subadmin-add-product'
                        element={<SubadminAddProduct />}
                      />
                      <Route
                        path='subadmin-product-status'
                        element={<SubadminProductStatus />}
                      />
                      <Route
                        path='subadmin-product-submitted'
                        element={<SubadminProductSubmitted />}
                      />
                      <Route
                        path='subadmin-edit-approval'
                        element={<SubadminEditApproval />}
                      />
                    </Route>

                    <Route
                      path='admin'
                      element={<Admin />}
                    />
                    <Route
                      path='approved-delivery'
                      element={<ApprovedDelivery />}
                    />
                  </Routes>
                </Suspense>
              </Router>
            </OrderProvider>
          </ProductProvider>
        </AdminProvider>
      </FurnitureSearch.Provider>
    </AuthProvider>
  );
}

export default App;
