import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, message, Button } from "antd";
import LoadingButtonWide from "../../LoadingButtonWide";
import { postReq } from "../../../configs/ApiUrl";
import { OrderContext } from "./OrderContext";

const KlarnaPayment = ({
  customerId,
  orderItems,
  shippingAddress,
  deliverySlots,
  totalAmount,
  prepareOrderData,
  navigateToConfirmation,
}) => {
  const [loading, setLoading] = useState(false);
  const [htmlSnippet, setHtmlSnippet] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { orderData } = useContext(OrderContext);

  const navigate = useNavigate();
  const encodeURIComponentSafe = (str) =>
    encodeURIComponent(str).replace(/%5B/g, "[").replace(/%5D/g, "]");

  const confirmationUrl =
    process.env.NODE_ENV === "development"
      ? `https://localhost:3000/order-success-klarna?order_id={checkout.order.id}`
      : `https://24mobler-apps.netlify.app/order-success-klarna?order_id={checkout.order.id}`;

  localStorage.setItem("test", "testValue");

  const orderDetails = {
    purchase_country: "se",
    purchase_currency: "SEK",
    locale: "sv-SE",
    order_amount: totalAmount * 100,
    order_tax_amount: 0,
    order_lines: orderItems.map((item) => {
      const totalAmount = item.grandTotal  * item.quantity * 100;

      return {
        type: "physical",
        reference: item.id,
        name: item.name,
        quantity: item.quantity,
        quantity_unit: "pcs",
        unit_price: item.grandTotal * 100,
        tax_rate: 0,
        total_amount: totalAmount,
        total_discount_amount: 0,
        total_tax_amount: 0,
      };
    }),
    shipping_address: {
      given_name: shippingAddress.firstName,
      family_name: shippingAddress.lastName,
      email: shippingAddress.email,
      street_address: shippingAddress.street,
      postal_code: shippingAddress.postalCode,
      city: shippingAddress.city,
      region: shippingAddress.state,
      phone: shippingAddress.phone,
      country: "se",
    },
    billing_address: {
      given_name: shippingAddress.firstName,
      family_name: shippingAddress.lastName,
      email: shippingAddress.email,
      street_address: shippingAddress.street,
      postal_code: shippingAddress.postalCode,
      city: shippingAddress.city,
      region: shippingAddress.state,
      phone: shippingAddress.phone,
      country: "se",
    },
    merchant_urls: {
      terms: "https://24mobler.se/en/kopvillkor/",
      checkout: "https://24mobler-apps.netlify.app/shopping-home",
      confirmation: `https://24mobler-apps.netlify.app/order-success-klarna?order_id={checkout.order.id}`,
      push: "https://www.example.com/api/push",
    },
  };


  const handleKlarnaPayment = async () => {
    try {
      const orderData = await prepareOrderData(); // Call the function here
      if (!orderData) {
        setLoading(false);
        return;
      }

      setLoading(true);
      const klarnaResp = await postReq("/klarna/create-order", orderDetails);
      if (klarnaResp?.html_snippet) {
        setHtmlSnippet(klarnaResp.html_snippet);
        setIsModalVisible(true);
      } else {
        setLoading(false);
        message.error("Klarna Payment Failed", 1);
      }
    } catch (error) {
      console.error("Klarna create order error:", error); // Log the error
      setLoading(false);
      message.error("Klarna Payment Failed", 1);
    }
  };

  useEffect(() => {
    if (htmlSnippet) {
      const container = document.getElementById("klarna-checkout-container");
      if (container) {
        container.innerHTML = htmlSnippet;

        const scriptsTags = container.getElementsByTagName("script");
        for (let i = 0; i < scriptsTags.length; i++) {
          const parentNode = scriptsTags[i].parentNode;
          const newScriptTag = document.createElement("script");
          newScriptTag.type = "text/javascript";
          newScriptTag.text = scriptsTags[i].text;
          parentNode.removeChild(scriptsTags[i]);
          parentNode.appendChild(newScriptTag);
        }
      }
      setLoading(false);
    }
  }, [htmlSnippet]);

  const handleCancel = () => {
    setIsModalVisible(false);
    navigateToConfirmation();
  };

  return (
    <div className='flex justify-center mt-4'>
      <div>
        <div className='flex justify-center'>
          <button
            onClick={handleKlarnaPayment}
            disabled={loading}
            className='w-full px-16 py-4 text-xl text-center font-semibold rounded-lg cursor-pointer shadow-lg shadow-slate-300 text-white transition-all duration-100 delay-75 bg-klarnaBlack flex items-center justify-center mb-2'>
            {loading ? (
              <LoadingButtonWide />
            ) : (
              <>
                Continue with
                <img
                  src='/assets/icons/klarna-logo.png'
                  className='w-16 h-7 inline-block ml-2'
                  alt='Klarna logo'
                />
              </>
            )}
          </button>
        </div>

        <Modal
          title='Klarna Payment'
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button
              key='back'
              onClick={handleCancel}>
              Close
            </Button>,
          ]}>
          <div id='klarna-checkout-container'></div>
        </Modal>
      </div>
    </div>
  );
};

export default KlarnaPayment;