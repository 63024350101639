import React, { useState, useEffect } from "react";
import ProductImages from "../uploads/ProductImages.js";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
import Checkbox from "./Checkbox.js";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { loadProductData } from "./pages/util/AddProductUtils.js";
import PageSubheader from "./PageSubheader.js";

const NewProductForm = ({
  errors,
  setErrors,
  product,
  setProductState,
  handleCreateProduct,
  isLoading,
  completedSteps,
  additionalFields, // additional form fields specific to Admin or Subadmin
  headerLink,
}) => {
  const { t } = useTranslation([
    "addProductPage",
    "productPage",
    "forms",
    "productTypes",
    "altText",
    "admin",
    "subadmin"
  ]);
  const currentLanguage = i18next.language;
  const [productData, setProductData] = useState({
    options: [],
    colors: [],
    condition: [],
  });

  useEffect(() => {
    loadProductData(currentLanguage, setProductData);
  }, [currentLanguage]);

  const { options, colors, condition } = productData;

  return (
    <>
      <PageSubheader
        backLink={headerLink}
        title={t('admin:addProductButton')}
        description={t("admin:addProductExplainer")}
      />

      <div className='sticky top-0 z-50 flex justify-center items-center mb-6 p-4 bg-white text-center drop-shadow'>
        <div className='flex flex-col items-center'>
          <img
            alt={t("altText:circle")}
            src='./../assets/icons/progress-circle-unchecked.svg'
            width={40}
          />
          <p className='mt-1 font-xs text-gray-400'>Basic Details</p>
        </div>
        <div className='flex flex-col justify-center mx-2'>
          <progress
            className='progress-bar'
            value={(completedSteps / 9) * 100}
            max='100'
          />
        </div>
        <div className='flex flex-col items-center'>
          <img
            alt={t("altText:circle")}
            src='./../assets/icons/progress-circle-unchecked.svg'
            width={40}
          />
          <p className='mt-1 font-xs text-gray-400'>Addons & Confirm</p>
        </div>
      </div>

      <main className='p-6 font-bold text-lg'>
        <form onSubmit={handleCreateProduct}>
          <article className='mb-4'>
            <div className='relative'>
              <div className='absolute text-red-500 text-base font-semibold top-[-25px]'>
                {errors && errors}
              </div>
            </div>
            <div className='bg-white p-4 mb-8 shadow-lg'>
              <div className='flex'>
                <img
                  alt={t("altText:verticalDivider")}
                  src='./../assets/icons/divider-vertical.svg'
                  className='w-8 h-7'
                />
                <h2 className='text-lg mb-3 font-semibold'>
                  {t("addProductPage:photosHeader")}
                </h2>
              </div>
              <div className='flex flex-col items-center'>
                <ProductImages onFileListChange={(images) => setProductState({ ...product, imageNames: images })} />
              </div>
            </div>
          </article>
          <div className='flex justify-center'>
            <img
              alt={t("altText:horizontalDivider")}
              src='./../assets/icons/divider-horizontal.svg'
              className='w-80'
            />
          </div>
          <article>
            <div className='bg-white p-4 mt-8 mb-8 shadow-lg'>
              <div className='flex'>
                <img
                  alt={t("altText:verticalDivider")}
                  src='./../assets/icons/divider-vertical.svg'
                  className='w-8 h-7'
                />
                <h2 className='mb-4'>{t("addProductPage:detailsHeader")}</h2>
              </div>
              <div className='text-base pl-3 mb-3 font-semibold mt-2'>
                <h2>{t("addProductPage:namePlace")}</h2>
                <input
                  name='title'
                  placeholder={t("addProductPage:nameLabel")}
                  className='focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] pl-2 mt-1 rounded-md w-full'
                  onChange={(e) =>
                    setProductState({ ...product, title: e.target.value })
                  }
                />
              </div>
              <div className='text-base pl-3 mb-3 mt-6 font-semibold mt-2'>
                <h2>{t("addProductPage:priceLabel")}</h2>
                <input
                  name='price'
                  type='number'
                  min='0'
                  className='focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] pl-2 mt-1 h-10 w-full rounded-md'
                  value={product.price}
                  onChange={(e) =>
                    setProductState({ ...product, price: e.target.value })
                  }
                />
              </div>
              <div className='text-base pl-3 mb-3 mt-6 font-semibold mt-2'>
                <h2>{t("addProductPage:conditionLabel")}</h2>
                <Select
                  className='mt-1 mb-5'
                  options={condition}
                  labelField='condition'
                  valueField='id'
                  keepOpen={false}
                  dropdownHandle={false}
                  searchable={false}
                  backspaceDelete={false}
                  style={{
                    outline: "none",
                    borderColor: "rgb(168 162 158)",
                    borderRadius: "0.375rem",
                    fontSize: "1rem",
                    width: "100%",
                  }}
                  onChange={(selectedOptions) => {
                    const selectedCondition =
                      selectedOptions.length > 0
                        ? selectedOptions[0].condition
                        : "";
                    setProductState({
                      ...product,
                      condition: selectedCondition,
                    });
                  }}
                />
              </div>
              <div className='text-base pl-3 mb-3 mt-6 font-semibold mt-2'>
                <h2>{t("addProductPage:modelLabel")}</h2>
                <input
                  name='name'
                  type='text'
                  placeholder={t("addProductPage:modelPlace")}
                  className='focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] pl-2 mt-1 h-10 w-full rounded-md'
                  onChange={(e) =>
                    setProductState({ ...product, model: e.target.value })
                  }
                />
              </div>
            </div>
          </article>
          <div className='flex justify-center'>
            <img
              alt={t("altText:horizontalDivider")}
              src='./../assets/icons/divider-horizontal.svg'
              className='w-80'
            />
          </div>
          <article>
            <div className='bg-white p-4 mt-8 mb-8 shadow-lg'>
              <div className='flex'>
                <img
                  alt={t("altText:verticalDivider")}
                  src='./../assets/icons/divider-vertical.svg'
                  className='w-8 h-7'
                />
                <h2 className='text-lg mb-3 font-semibold'>
                  {t("addProductPage:furtherDetailsHeader")}
                </h2>
              </div>
              <div className='text-base pl-3 mb-3 font-semibold mt-2'>
                <h2>{t("addProductPage:typeLabel")}</h2>
                <Select
                  className='mt-1 mb-5'
                  options={options}
                  labelField='category'
                  valueField='id'
                  keepOpen={false}
                  dropdownHandle={false}
                  searchable={false}
                  backspaceDelete={false}
                  style={{
                    borderColor: "rgb(168 162 158)",
                    borderRadius: "0.375rem",
                    fontSize: "1rem",
                    width: "100%",
                  }}
                  onChange={(selectedOptions) => {
                    const selectedCategory =
                      selectedOptions.length > 0
                        ? selectedOptions[0].category
                        : "";
                    setProductState({ ...product, category: selectedCategory });
                  }}
                />
              </div>
              <div className='text-base pl-3 mb-3 font-semibold mt-2'>
                <h2>{t("addProductPage:colorLabel")}</h2>
                <Select
                  className='mt-1 mb-5'
                  options={colors}
                  labelField='color'
                  valueField='id'
                  keepOpen={false}
                  dropdownHandle={false}
                  searchable={false}
                  backspaceDelete={false}
                  style={{
                    borderColor: "rgb(168 162 158)",
                    borderRadius: "0.375rem",
                    fontSize: "1rem",
                    width: "100%",
                  }}
                  onChange={(selectedOptions) => {
                    const selectedColor =
                      selectedOptions.length > 0
                        ? selectedOptions[0].color
                        : "";
                    setProductState({ ...product, color: selectedColor });
                  }}
                />
              </div>
              <div className='text-base pl-3 mb-3 font-semibold mt-2'>
                <h2>{t("addProductPage:dimensionsLabel")}</h2>
                <div className='flex flex-row justify-between'>
                  <div className='text-base mb-3 font-semibold mt-2 mr-2'>
                    <h2>{t("productPage:height")}</h2>
                    <input
                      name='height'
                      type='number'
                      min='0'
                      className='focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] w-[70px] pl-2 h-10 rounded-md'
                      onChange={(e) =>
                        setProductState({
                          ...product,
                          dimension: {
                            ...product.dimension,
                            height: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className='text-base mb-3 font-semibold mt-2 mr-2'>
                    <h2>{t("productPage:length")}</h2>
                    <input
                      name='width'
                      type='number'
                      min='0'
                      className='focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] w-[70px] pl-2 h-10 rounded-md'
                      onChange={(e) =>
                        setProductState({
                          ...product,
                          dimension: {
                            ...product.dimension,
                            width: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className='text-base mb-3 font-semibold mt-2 mr-2'>
                    <h2>{t("productPage:depth")}</h2>
                    <input
                      name='depth'
                      type='number'
                      min='0'
                      className='focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] w-[70px] pl-2 h-10 rounded-md'
                      onChange={(e) =>
                        setProductState({
                          ...product,
                          dimension: {
                            ...product.dimension,
                            depth: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='text-base pl-3 mb-3 mt-6 font-semibold mt-2'>
                <h2>{t("addProductPage:roomLabel")}</h2>
                <div
                  style={{ width: "320px" }}
                  className='flex flex-wrap'>
                  <Checkbox
                    name='balcony'
                    label={t("productTypes:balcony")}
                    inputDisplay={product}
                    setInputDisplay={setProductState}
                  />
                  <Checkbox
                    name='bathroom'
                    label={t("productTypes:bathroom")}
                    inputDisplay={product}
                    setInputDisplay={setProductState}
                  />
                  <Checkbox
                    name='bedroom'
                    label={t("productTypes:bedroom")}
                    inputDisplay={product}
                    setInputDisplay={setProductState}
                  />
                  <Checkbox
                    name='foyer'
                    label={t("productTypes:foyer")}
                    inputDisplay={product}
                    setInputDisplay={setProductState}
                  />
                  <Checkbox
                    name='kitchen'
                    label={t("productTypes:kitchen")}
                    inputDisplay={product}
                    setInputDisplay={setProductState}
                  />
                  <Checkbox
                    name='living room'
                    label={t("productTypes:livingRoom")}
                    inputDisplay={product}
                    setInputDisplay={setProductState}
                  />
                  <Checkbox
                    name='office'
                    label={t("productTypes:office")}
                    inputDisplay={product}
                    setInputDisplay={setProductState}
                  />
                </div>
              </div>
              <div className='text-base pl-3 mb-3 mt-6 font-semibold'>
                <h2>{t("addProductPage:descLabel")}</h2>
                <textarea
                  name='description'
                  className='focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] w-full h-[150px] pl-2 pt-2 rounded-md'
                  onChange={(e) =>
                    setProductState({ ...product, description: e.target.value })
                  }
                />
              </div>
            </div>
          </article>
          {additionalFields}
          <div className='flex justify-center'>
            <img
              alt={t("altText:horizontalDivider")}
              src='./../assets/icons/divider-horizontal.svg'
              className='w-80'
            />
          </div>
          <div className=' text-red-500 text-base font-semibold '>
            {errors && errors}
          </div>
          <div className='flex justify-center p-5 '>
            <button
              type='submit'
              disabled={isLoading}
              className='w-full'>
              <div
                className={`btn-main flex items-center justify-center ${
                  isLoading ? "opacity-50 pointer-events-none" : ""
                }`}>
                {isLoading ? (
                  <h1 className='text-white px-10 text-lg text-center font-semibold'>
                    Loading...
                  </h1>
                ) : (
                  <h1 className='text-white px-10 text-lg text-center font-semibold'>
                    Review Details
                  </h1>
                )}
              </div>
            </button>
          </div>
        </form>
      </main>
    </>
  );
};

export default NewProductForm;