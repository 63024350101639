import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../components/pages/util/LanguageSelect";
import { RxPerson } from "react-icons/rx";
import { FaHome, FaHeart, FaPlusCircle } from "react-icons/fa";
import { MdOutlineShoppingCart } from "react-icons/md";

export default function PageHeader() {
  const { t } = useTranslation(["altText", "homepage"]);
  const location = useLocation();

  // Helper function to check if the current route is active
  const isActive = (path) => location.pathname === path;

  return (
    <header className='fixed top-0 left-0 right-0 bg-cromboGray flex justify-between items-center px-4 py-2 shadow-md z-50 h-16'>
      <Link
        to='/shopping-home'
        className='h-full'>
        <img
          alt={t("logo")}
          src='./../assets/images/24Mobler.png'
          className='h-full object-contain'
        />
      </Link>
      <div className='flex items-center space-x-6'>
        {/* Icons and labels visible only on desktop */}
        <div className='hidden md:flex items-center justify-evenly w-full space-x-12'>
          <Link
            to='/shopping-home'
            className={`flex items-center space-x-2 ${
              isActive("/shopping-home")
                ? "text-cromboOrangeFade"
                : "text-white"
            }`}>
            <FaHome size={20} />
            <span className='text-md font-bold'>{t("homepage:buyButton")}</span>
          </Link>
          <Link
            to='/selling-add-furniture'
            className={`flex items-center space-x-2 ${
              isActive("/selling-add-furniture")
                ? "text-cromboOrangeFade"
                : "text-white"
            }`}>
            <FaPlusCircle size={20} />
            <span className='text-md font-bold'>{t("homepage:sellButton")}</span>
          </Link>
          <Link
            to='/catalogue-favourites'
            className={`flex items-center space-x-2 ${
              isActive("/catalogue-favourites")
                ? "text-cromboOrangeFade"
                : "text-white"
            }`}>
            <FaHeart size={20} />
            <span className='text-md font-bold'>{t("homepage:favouriteButton")}</span>
          </Link>
          <Link
            to='/catalogue-cart'
            className={`flex items-center space-x-2 ${
              isActive("/catalogue-cart")
                ? "text-cromboOrangeFade"
                : "text-white"
            }`}>
            <MdOutlineShoppingCart size={20} />
            <span className='text-md font-bold'>{t("homepage:cartButton")}</span>
          </Link>
          <Link
            to='/user-profile'
            className={`flex items-center space-x-2 ${
              isActive("/user-profile") ? "text-cromboOrangeFade" : "text-white"
            }`}>
            <RxPerson size={20} />
            <span className='text-md font-bold'>{t("homepage:profileButton")}</span>
          </Link>
        </div>
        {/* Profile icon visible only on mobile */}
        <Link
          to='/user-profile'
          className='text-white md:hidden'>
          <RxPerson size={25} />
        </Link>
        <LanguageSelect />
      </div>
    </header>
  );
}
