import React, { useEffect, useState } from "react";
import { getReq } from "../../configs/ApiUrl.js";
import { LoadingSpinner } from "./util/LoadingSpinner.js";
import defaultImg from "../../images/defaultImg.webp";
import moment from "moment";
import { useTranslation } from "react-i18next";
import PageHeader from "../PageHeader.js";
import AlwaysTop from "./util/AlwaysTop.js";
import PageSubheader from "../PageSubheader.js";
import locationMark from "../../images/locationMark.svg";

function ApprovedDelivery() {
  const { t } = useTranslation(["userProfile", "tables", "altText", "admin"]);
  const [customerOrders, setCustomerOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserAndOrders = async () => {
      setLoading(true);
      try {
        // Fetch the authenticated user's profile to get the user ID
        const userProfileResp = await getReq("/user/profile");
        const userId = userProfileResp?.user?._id;

        if (!userId) {
          console.error("User ID is missing from profile response");
          return;
        }

        // Fetch customer orders using the user ID
        const ordersResp = await getReq("/order/customer"); // Fetch orders for the authenticated user
        if (ordersResp?.success) {
          setCustomerOrders(ordersResp.customerOrders || []);
        } else {
          console.error(
            "Failed to fetch orders:",
            ordersResp?.message || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching customer orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserAndOrders();
    AlwaysTop();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  const hasOrders = customerOrders.some(
    (order) => Array.isArray(order?.orderItems) && order.orderItems.length
  );

  if (!hasOrders) {
    return (
      <div className="flex items-center justify-center w-full h-96">
        <h1 className="text-xl font-bold text-center text-gray-500">
          {t("admin:noOrders")}
        </h1>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col items-center min-h-screen">
        <PageSubheader
          backLink="/user-profile"
          title={t("userProfile:approvedDeliveryButton")}
          description={t("userProfile:ActiveOrdersExplainer")}
          className="mb-0"
        />
        <main className="flex flex-col justify-center bg-white w-full drop-shadow mt-0">
          <div className="flex flex-col">
            {customerOrders.map((order, orderIndex) =>
              order.orderItems.map((item, itemIndex) => (
                <React.Fragment key={`${orderIndex}-${itemIndex}`}>
                  <div className="order-card flex flex-col md:flex-row w-full px-4 py-6">
                    {/* Product Image and Title */}
                    <div className="w-full md:w-1/3">
                      <div className="relative">
                        <img
                          className="w-full h-48 object-cover"
                          src={
                            item.product?.mainImage ||
                            item.product?.imageNames?.[0] ||
                            defaultImg
                          }
                          alt={t("altText:firstImage")}
                          onError={(e) => (e.target.src = defaultImg)}
                        />
                        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-1">
                          <span className="text-xs truncate">
                            {item.product?.title?.length > 15
                              ? item.product.title.substring(0, 15) + "..."
                              : item.product?.title}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* Address */}
                    <div className="w-full md:w-1/3 mt-4 md:mt-0 md:pl-4 flex items-center">
                      <div className="flex items-center">
                        <img
                          className="w-8 h-8 mr-2"
                          src={locationMark}
                          alt={t("altText:location")}
                        />
                        <span className="text-center">
                          {`${order.shippingAddress?.street}, ${order.shippingAddress?.state}, ${order.shippingAddress?.city}.`}
                        </span>
                      </div>
                    </div>
                    {/* Delivery Slots */}
                    <div className="w-full md:w-1/3 mt-4 md:mt-0 md:pl-4 flex flex-col">
                      {order.deliverySlots?.length ? (
                        <div className="flex flex-wrap justify-center md:justify-start">
                          {order.deliverySlots.map((slot, ind) => (
                            <div
                              key={ind}
                              className="m-2 px-4 py-2 flex flex-col justify-center items-center bg-white border border-gray-200 shadow-sm rounded-md"
                            >
                              <span className="text-cromboOrangeFade text-sm">
                                {moment(slot.date).format("DD/MM/YYYY")}
                              </span>
                              <span className="text-xs">{slot.time}</span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <span className="text-gray-500">
                          {t("admin:noDeliverySlots")}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* Divider */}
                  {(itemIndex < order.orderItems.length - 1 ||
                    orderIndex < customerOrders.length - 1) && (
                    <hr className="border-t-2 border-cromboOrange" />
                  )}
                </React.Fragment>
              ))
            )}
          </div>
        </main>
      </div>
    </>
  );
}

export default ApprovedDelivery;
