import { Link } from "react-router-dom";
import PageFooter from "../PageFooter.js";
import { useEffect, useState } from "react";
import { putReq, getReq } from "../../configs/ApiUrl.js"; // Update with correct import for your get request
import { message } from "antd";
import { useTranslation } from "react-i18next";
import PageHeader from "../PageHeader.js";
import PageSubheader from "../PageSubheader.js";

function UserContact() {
  const { t } = useTranslation(["userProfile", "forms", "altText"]);

  // State for user data, loading, and subadmin role
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    phone: "",
    street: "",
    city: "",
    postalCode: "",
    state: "",
    userId: "",
    companyName: "",
  });
  const [loading, setLoading] = useState(false);
  const [isSubAdmin, setIsSubAdmin] = useState(false);

  // Fetch user profile from the backend
  useEffect(() => {
    const fetchUserProfile = async () => {
      setLoading(true);
      try {
        const response = await getReq("/user/profile");
        if (response.success && response.user) {
          const { email, username, address, _id, companyName, role } = response.user;
          setFormData({
            email,
            username,
            phone: address?.phone || "",
            street: address?.street || "",
            city: address?.city || "",
            postalCode: address?.postalCode || "",
            state: address?.state || "",
            userId: _id,
            companyName: companyName || "",
          });
          setIsSubAdmin(role === "subadmin");
        } else {
          message.error("Failed to load user profile.");
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        message.error("Something went wrong while loading user profile.");
      } finally {
        setLoading(false);
      }
    };
    fetchUserProfile();
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission for updating user data
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const updatedData = {
        email: formData.email,
        username: formData.username,
        phone: formData.phone,
        address: {
          street: formData.street,
          city: formData.city,
          postalCode: formData.postalCode,
          state: formData.state,
        },
        userId: formData.userId,
      };
      if (isSubAdmin) {
        updatedData.companyName = formData.companyName;
      }

      const updateResp = await putReq("/user/update", updatedData);

      if (updateResp?.success) {
        message.success("Update successful");
      } else {
        message.error("Update failed");
      }
    } catch (error) {
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <PageSubheader
        backLink="/user-profile"
        title={t("userProfile:contactInfoButton")}
        description={t("userProfile:contactInfoButton")}
      />
      <div className="flex flex-col items-center min-h-screen">
        <div className="bg-white drop-shadow w-full p-10">
          <main className="flex justify-center">
            <div className="max-w-3xl">
              <UserForm
                title=""
                fields={[
                  {
                    label: t("forms:emailLabel"),
                    name: "email",
                    type: "email",
                  },
                  {
                    label: t("forms:usernameLabel"),
                    name: "username",
                    type: "text",
                  },
                  isSubAdmin && {
                    label: t("forms:companyNameLabel"),
                    name: "companyName",
                    type: "text",
                  },
                  { label: t("forms:phoneLabel"), name: "phone", type: "tel" },
                  {
                    label: t("forms:streetLabel"),
                    name: "street",
                    type: "text",
                  },
                  { label: t("forms:cityLabel"), name: "city", type: "text" },
                  {
                    label: t("forms:postalLabel"),
                    name: "postalCode",
                    type: "text",
                  },
                  {
                    label: t("forms:provinceLabel"),
                    name: "state",
                    type: "text",
                  },
                ].filter(Boolean)}
                buttonText={t("forms:profileUpdateButton")}
                formData={formData}
                onChange={handleChange}
                onSubmit={handleSubmit}
                loading={loading}
              />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

// UserForm Component for displaying the form
function UserForm({
  title,
  fields,
  buttonText,
  formData,
  onChange,
  onSubmit,
  loading,
}) {
  return (
    <form className="mb-8" onSubmit={onSubmit}>
      <div className="mb-2 text-2xl font-semibold">
        <h1>{title}</h1>
      </div>
      {fields.map((field, index) => (
        <div key={index} className="flex flex-col mb-2">
          <div className="flex">
            <label htmlFor={field.name} className="w-32 mr-2">
              {field.label}
            </label>
          </div>
          <input
            name={field.name}
            id={field.name}
            type={field.type}
            autoComplete="none"
            className={`form-field w-64 h-10 ${
              field.name === "email" ? "bg-gray-200" : ""
            }`}
            value={formData[field.name]}
            onChange={onChange}
            disabled={field.name === "email"}
          />
        </div>
      ))}
      <div className="flex justify-center mt-12">
        <button type="submit" disabled={loading}>
          <div
            className={`w-full btn-main flex items-center justify-center mb-2 cursor-pointer ${
              loading ? "opacity-50 pointer-events-none" : ""
            }`}
          >
            {loading ? (
              <span className="text-white text-lg text-center font-semibold">
                Loading...
              </span>
            ) : (
              <span className="text-white text-2xl text-center font-semibold">
                {buttonText}
              </span>
            )}
          </div>
        </button>
      </div>
    </form>
  );
}

export default UserContact;
