import { useContext, useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { useNavigate, Link } from "react-router-dom";
import PageHeader from "../PageHeader.js";
import PageFooter from "../PageFooter.js";
import { FurnitureSearch } from "../../App.js";
import OrderCard from "./util/OrderCard.js";
import { useTranslation } from "react-i18next";
import DiscountCodeCheck from "./util/DiscountCodeCheck.js";
import AlwaysTop from "./util/AlwaysTop.js";

function CatalogueCart() {
  const { t } = useTranslation(["cartPage", "altText"]);

  // Context and state variables
  const [searchTerm, setSearchTerm] = useContext(FurnitureSearch);
  let shoppingCart = searchTerm.shoppingCart || [];

  const navigate = useNavigate();

  // Toggles the visibility of the checkout button
  const [isVisible, setIsVisible] = useState(true);

  // Ensure all values are numbers and provide default values if not present
  const sanitizeItem = (item) => {
    const productCost = item.productCost != null ? Number(item.productCost) : 0;
    const movingCost = item.movingCost != null ? Number(item.movingCost) : 0;
    const extraTotal = item.extraTotal != null ? Number(item.extraTotal) : 0;
    const discount = item.discount != null ? Number(item.discount) : 0;
    const subadminEmail = item.subadminEmail || "";

    const bonusDiscount =
      item.bonusDiscount != null ? Number(item.bonusDiscount) : 0;

    const grandTotal =
      productCost + movingCost + extraTotal - bonusDiscount - discount;

    const sanitizedItem = {
      ...item,
      grandTotal,
      movingCost,
      productCost,
      extras: Array.isArray(item.extras) ? item.extras : [],
      extraQuantity: Array.isArray(item.extraQuantity)
        ? item.extraQuantity
        : [],
      extraCost: Array.isArray(item.extraCost) ? item.extraCost : [],
      extraTotal,
      bonusDiscount,
      discount,
      subadminEmail,
    };
    return sanitizedItem;
  };

  const sanitizedCart = shoppingCart.map(sanitizeItem);

  // Grand total state
  const [grandTotal, setGrandTotal] = useState(() => {
    const initialTotal = sanitizedCart.reduce(
      (total, item) => total + item.grandTotal,
      0
    );
    return initialTotal;
  });

  // Captures the total before the discount is applied
  const originalTotal = sanitizedCart.reduce(
    (total, item) => total + item.grandTotal,
    0
  );

  useEffect(() => {
    if (shoppingCart.length === 0) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [shoppingCart]);

  useEffect(() => {
    // Update grand total whenever the shopping cart changes
    const updatedTotal = sanitizedCart.reduce((total, item) => {
      return total + item.grandTotal;
    }, 0);
    setGrandTotal(updatedTotal);
  }, [shoppingCart]);

  const handleCheckout = () => {
    navigate("/checkout", {
      state: {
        grandTotal,
        shoppingCart: sanitizedCart,
        deliverySlots: [],
      },
    });
  };

  // A message to be displayed when the shopping cart is empty
  function emptyCart() {
    if (shoppingCart.length === 0) {
      return (
        <div className='flex flex-col items-center justify-center mt-24 mb-24'>
          <img
            src='/assets/icons/bag-icon.svg'
            alt={t("altText:logo")}
            width={120}
            className='mb-4'
          />
          <h2 className='text-center text-2xl font-bold mb-2'>
            {t("cartSubheader")}
          </h2>
          <p className='text-center text-stone-400'>{t("cartEmpty")}</p>
        </div>
      );
    }
  }

  useEffect(() => {
    AlwaysTop();
  }, []);

  return (
    <div className='flex flex-col items-center min-h-screen bg-gray-100'>
      <PageHeader />
      <div className='w-full max-w-screen-lg px-4 mt-20'>
        <main className='mt-4'>
          {emptyCart()}
          {sanitizedCart.length > 0 && (
            <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mt-4'>
              {sanitizedCart.map((currentObject, i) => (
                <div
                  className='relative'
                  key={currentObject.id || i}>
                  {/* Remove button */}
                  <div className='text-red-500 hover:text-red-400 absolute right-6 top-4 cursor-pointer z-10'>
                    <div className='border-4 bg-white border-white rounded-lg'>
                      <IoMdClose
                        size={20}
                        key={i}
                        id={i}
                        onClick={() => {
                          setSearchTerm((prev) => {
                            const newShoppingCart = [...prev.shoppingCart];
                            newShoppingCart.splice(i, 1);
                            return {
                              ...prev,
                              shoppingCart: newShoppingCart,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <OrderCard
                    name={currentObject.name}
                    quantity={currentObject.quantity}
                    productCost={currentObject.productCost}
                    movingCost={currentObject.movingCost}
                    extras={currentObject.extras}
                    extraQuantity={currentObject.extraQuantity}
                    extraCost={currentObject.extraCost}
                    extraTotal={currentObject.extraTotal}
                    bonusDiscount={currentObject.bonusDiscount}
                    discount={currentObject.discount}
                    grandTotal={currentObject.grandTotal}
                    image={currentObject.image}
                  />
                </div>
              ))}
            </div>
          )}

          {sanitizedCart.length > 0 && (
            <>
              <div className='mt-6'>
                <DiscountCodeCheck
                  grandTotal={grandTotal}
                  setGrandTotal={setGrandTotal}
                  shoppingCart={sanitizedCart}
                />
              </div>

              <div className='flex flex-col items-center mt-6 p-4 bg-white shadow-md rounded-2xl'>
                <h3 className='text-2xl text-center text-cromboOrange font-bold'>
                  {t("cartTotal")}{" "}
                  {grandTotal.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  kr.
                </h3>
                {originalTotal !== grandTotal && (
                  <div className='text-stone-500 line-through mt-2 text-xl'>
                    {originalTotal
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    kr.
                  </div>
                )}
              </div>

              {isVisible && (
                <div
                  className='btn-main flex items-center justify-center mt-8 mb-8 hover:bg-cromboOrangeDark transition-colors duration-200'
                  onClick={handleCheckout}>
                  {t("checkoutButton")}
                </div>
              )}
            </>
          )}
        </main>
      </div>

      {/* Conditionally render PageFooter on mobile screens */}
      <div className='block md:hidden'>
        <PageFooter />
      </div>
    </div>
  );
}

export default CatalogueCart;
