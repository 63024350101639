import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TbCircle1Filled, TbCircle2Filled, TbCircle3Filled } from 'react-icons/tb';
import { FaCopy } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

export const OrderModal = () => {
    const { t } = useTranslation('utilityPages');
    const navigate = useNavigate();
    const [copiedText, setCopiedText] = useState(null);

    const handleCopy = (text, id) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopiedText(id);
            setTimeout(() => {
                setCopiedText(null);
            }, 2000);
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    return (
        <div className="flex flex-col items-center">
            <main className="w-full flex flex-col items-center justify-center min-h-screen bg-gray-100">
                <div className="w-full flex flex-col items-center justify-center p-5 mt-10 mb-10 py-8 bg-white rounded-[30px]">
                    <div className="flex flex-col justify-center items-center space-y-4">
                        <div className="flex items-center">
                            <img src='/assets/icons/checkbox.svg' alt={t("altText:checkbox")} width={120}></img>
                            <h1 className="text-2xl font-extrabold">{t('orderCongrats')}</h1>
                        </div>
                        <hr className="w-full my-2"></hr>
                        <div className="flex p-4 text-lg font-bold bg-white drop-shadow-lg">
                        <ul className="flex flex-col items-center justify-center">
                        <p className='mb-4 text-lg font-semibold'>{t('orderSubtitle')}</p>
                        <div className='flex items-stretch justify-center rounded-lg'>
                            <div className="w-full flex-2 p-3 border-2 border-cromboOrangeFade rounded-l-lg flex items-center">
                                <TbCircle1Filled className="min-w-[28px] min-h-[28px] mr-2" />
                                <li className="mt-2">{copiedText === 'orderRecipient' ? 'Text copied to clipboard' : t('orderRecipient')}</li>
                            </div>
                            <div className='w-full flex-1 p-3 bg-cromboOrange rounded-r-lg flex items-center justify-center' onClick={() => handleCopy('Crombo AB', 'orderRecipient')}>
                                <FaCopy className="w-5 h-5 text-white" />
                            </div>
                        </div>
                        <img
                            alt="Horizontal divider"
                            src="./../assets/icons/divider-horizontal.svg"
                            className="w-80 py-4"
                        />
                        <div className="w-full flex-row p-4 border-2 border-cromboOrange rounded-lg">
                            <div className="flex">
                               <TbCircle2Filled className="w-[28px] h-[28px] mr-2" />
                               <li className="my-2">{t('orderMethods')}</li>
                            </div>
                            <div className='flex items-stretch justify-center rounded-lg'>
                            <div className="w-full flex-2 p-3 border-2 border-cromboOrangeFade rounded-l-lg flex items-center">
                                <li>{copiedText === 'swish' ? 'Text copied to clipboard' : 'Swish: 1231843754'}</li>
                            </div>
                            <div className='w-full flex-1 p-3 bg-cromboOrange rounded-r-lg flex items-center justify-center' onClick={() => handleCopy('1231843754', 'swish')}>
                                <FaCopy className="w-5 h-5 text-white" />
                            </div>
                            </div>
                                <li className="text-center">OR</li>
                            <div className='flex items-stretch justify-center rounded-lg'>
                            <div className="w-full flex-2 p-3 border-2 border-cromboOrangeFade rounded-l-lg flex items-center">
                                <li>{copiedText === 'bankGiro' ? 'Text copied to clipboard' : 'Bank Giro: 5765-6878'}</li>
                           </div>
                           <div className='w-full flex-1 p-3 bg-cromboOrange rounded-r-lg flex items-center justify-center' onClick={() => handleCopy('5765-6878', 'bankGiro')}>
                                <FaCopy className="w-5 h-5 text-white"/>
                           </div>
                           </div>
                        </div>
                        <img
                            alt="Horizontal divider"
                            src="./../assets/icons/divider-horizontal.svg"
                            className="w-80 py-4"
                        />
                        <div className='flex items-stretch justify-center rounded-lg'>
                            <div className="w-full flex-2 p-3 border-2 border-cromboOrangeFade rounded-l-lg flex items-center">
                                <TbCircle3Filled className="min-w-[28px] min-h-[28px] mr-2" />
                                <li className="mt-2">{copiedText === 'orderAccount' ? 'Text copied to clipboard' : t('orderAccount')}</li>
                            </div>
                            <div className="w-full flex-1 p-3 bg-cromboOrange rounded-r-lg flex items-center justify-center" onClick={() => handleCopy('Order Account', 'orderAccount')}>
                                <FaCopy className="w-5 h-5 text-white" />
                            </div>
                        </div>
                    </ul>
                        </div>
                        <p className="text-center font-bold">{t('orderInstructions')}</p>
                        <p className="text-center text-gray-600">{t('orderFurtherInstructions')}</p>
                    </div>
                    <button onClick={() => navigate('/shopping-home')} className="w-full btn-main mt-8">
                        {t('shoppingReturnButton')}
                    </button>
                </div>
            </main>
        </div>
    );
};