import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import DeleteWarningModal from "../pages/util/DeleteWarningModal";
import moment from "moment";
import { HiOutlineTrash } from "react-icons/hi2";
import { useEffect, useState } from "react";
import { LoadingSpinner } from "./util/LoadingSpinner";
import locationMark from "../../images/locationMark.svg";
import { getReq, putReq, delReq } from "../../configs/ApiUrl";
import { useTranslation } from "react-i18next";
import PageSubheader from "../PageSubheader";
import AlwaysTop from "./util/AlwaysTop";

function ActiveOrders() {
  const { t } = useTranslation([
    "admin",
    "subadmin",
    "tables",
    "altText",
    "forms",
    "productPage",
  ]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [updatedProduct, setUpdatedProduct] = useState({});
  const [selectedOption, setSelectedOption] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [subadminEmail, setSubadminEmail] = useState("");
  const [courier, setCourier] = useState("");

  const fetchUserRole = async () => {
    try {
      // First, fetch the user profile to get the user ID
      const userProfileResp = await getReq("/user/profile");
      const userId = userProfileResp?.user?._id;
  
      if (!userId) {
        console.error("User ID not found in profile response");
        return;
      }
  
      // Then, use the user ID to get the user role
      const roleResp = await getReq(`/user/${userId}`);
      if (roleResp?.success) {
        const { role, email, companyName } = roleResp;
        setUserRole(role);
  
        // If the user is a subadmin, set additional subadmin-specific details
        if (role === "subadmin") {
          setSubadminEmail(email);
          setCourier(companyName);
        }
      } else {
        console.error("Failed to fetch user role:", roleResp.message);
      }
    } catch (error) {
      console.error("Error fetching user role:", error.message);
    }
  };  

  const getAllOrders = async () => {
    try {
      setLoading(true);
      const ordersResp = await getReq(`/order/all`);
      if (ordersResp?.success) {
        const filteredOrders = ordersResp.allOrders.filter((order) =>
          userRole === "admin"
            ? order.orderStatus === "placed"
            : order.orderStatus === "placed" &&
              order.subadminEmail === subadminEmail
        );

        const sortedOrders = filteredOrders.sort(
          (a, b) => new Date(a.orderDate) - new Date(b.orderDate)
        );

        setAllOrders(sortedOrders);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const openDeleteModal = (id) => {
    setSelectedOrderId(id);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedOrderId(null);
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    try {
      if (selectedOrderId) {
        setLoading(true);
        const deleteResp = await delReq(`/order/delete/${selectedOrderId}`);
        if (deleteResp?.success) {
          message.success("Order deleted successfully", 2);
          setAllOrders((prevOrders) =>
            prevOrders.filter((order) => order._id !== selectedOrderId)
          );
          await getAllOrders();
        } else {
          message.error("Failed to delete order", 1);
        }
        setLoading(false);
        closeDeleteModal();
      }
    } catch (error) {
      message.error("Server error", 1);
      setLoading(false);
      closeDeleteModal();
    }
  };

  const handleConfirmPayment = async (product, order) => {
    try {
      setLoading(true);
      setUpdatedProduct({});
  
      const selectedSlots = selectedOption[order._id] || [];
  
      const formattedSlots = selectedSlots.map((slot) => ({
        date: new Date(slot.date).toISOString(),
        time: slot.time,
      }));
  
      // Only attempt to update delivery slots if there are any
      if (formattedSlots.length > 0) {
        const updateSlotsResp = await putReq(
          `/order/${order._id}/delivery-slots`,
          { deliverySlots: formattedSlots }
        );
  
        if (!updateSlotsResp?.success) {
          message.error("Failed to update delivery slots", 1);
          setLoading(false);
          return;
        }
      }
  
      if (userRole === "subadmin") {
        const updateEmailResp = await putReq(
          `/product/update-subadmin-email`,
          {
            id: product?._id,
            subadminEmail,
            courier,
          }
        );
        if (!updateEmailResp?.success) {
          throw new Error("Failed to update subadmin email and courier name");
        }
      }
  
      // Update the status to 'sold' explicitly
      const confirmResp = await putReq(`/product/confirm/payment`, {
        id: product?._id,
        status: 'sold', // Set status to 'sold' explicitly
        customerId: order?.customerId,
      });
  
      if (confirmResp?.success) {
        const orderUpdateResp = await putReq(`/order/confirm/${order._id}`);
        if (orderUpdateResp?.success) {
          message.success("Payment Confirmed and Order Status Updated", 2);
          setUpdatedProduct(confirmResp?.product);
  
          setAllOrders((prevOrders) =>
            prevOrders.map((o) =>
              o._id === order._id ? { ...o, orderStatus: "confirmed" } : o
            )
          );
        } else {
          message.error("Order Status Update Failed", 1);
        }
      } else {
        message.error("Confirm Payment Failed", 1);
      }
  
      setLoading(false);
    } catch (error) {
      console.error("Error in handleConfirmPayment:", error);
      message.error("Payment server error", 1);
      setLoading(false);
    }
  };  

  const handleDeclinePayment = async (product, order) => {
    try {
      setLoading(true);
      setUpdatedProduct({});

      const newStatus = order?.subadminEmail ? "approved" : "preapproved";

      const declineResp = await putReq(`/product/decline/payment`, {
        order_id: order?._id,
        id: product?._id,
        status: newStatus,
        customerId: order?.customerId,
        deliverySlots: order.deliverySlots,
      });

      if (declineResp?.success) {
        const orderUpdateResp = await putReq(
          `/order/${order._id}/update-status`,
          { status: "cancelled" }
        );

        if (orderUpdateResp?.success) {
          message.warning("Payment Declined and Order Cancelled", 2);
          setUpdatedProduct(declineResp?.product);

          setAllOrders((prevOrders) =>
            prevOrders.map((o) =>
              o._id === order._id
                ? {
                    ...o,
                    orderStatus: "cancelled",
                    orderItems: o.orderItems.map((i) =>
                      i.product._id === product._id
                        ? { ...i, product: { ...i.product, status: newStatus } }
                        : i
                    ),
                  }
                : o
            )
          );
        } else {
          message.error("Order Status Update Failed", 1);
        }
      } else {
        message.error("Decline Payment Failed", 1);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error in handleDeclinePayment:", error);
      message.error("Payment server error", 1);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserRole();
  }, []);

  useEffect(() => {
    if (userRole) {
      getAllOrders();
    }
  }, [userRole]);

  useEffect(() => {
    AlwaysTop();
  }, []);

  return (
    <div className='flex flex-col items-center min-h-screen'>
      <PageSubheader
        backLink={userRole === "admin" ? "/admin-menu" : "/subadmin-menu"}
        title={t("admin:activeButton")}
        description={t("admin:activeOrdersExplainer")}
      />

      <main className='flex flex-col justify-center mb-10 bg-white p-2 w-full drop-shadow'>
        <div className='overflow-x-auto'>
          {loading ? (
            <LoadingSpinner />
          ) : allOrders.length > 0 ? (
            <table className='table-auto w-full border-collapse rounded-t-xl -p-1'>
              <thead>
                <tr>
                  <th
                    className='px-24 py-2 bg-none border-b-4 border-cromboOrangeFade text-black font-semibold'
                    scope='col'>
                    {t("tables:image")}
                  </th>
                  <th
                    className='px-4 border-b-4 border-cromboOrangeFade text-black font-semibold'
                    scope='col'>
                    {t("admin:grandTotalHeader")}
                  </th>
                  <th
                    className='px-4 border-b-4 border-cromboOrangeFade text-black font-semibold'
                    scope='col'>
                    {t("tables:deliverySlots")}
                  </th>
                  <th
                    className='px-4 border-b-4 border-cromboOrangeFade text-black font-semibold'
                    scope='col'>
                    {t("tables:address")}
                  </th>
                  <th
                    className='px-4 border-b-4 border-cromboOrangeFade text-black font-semibold'
                    scope='col'>
                    {t("forms:phoneLabel")}
                  </th>
                  <th
                    className='px-4 border-b-4 border-cromboOrangeFade text-black font-semibold'
                    scope='col'>
                    {t("tables:payment")}
                  </th>
                  <th
                    className='px-4 border-b-4 border-cromboOrangeFade text-black font-semibold'
                    scope='col'>
                    {t("admin:actionHeader")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {allOrders.map((order, orderIndex) =>
                  order.orderItems
                    .filter((item) => item.product.status === "placed")
                    .map((item, itemIndex) => (
                      <tr
                        key={`${orderIndex}-${itemIndex}`}
                        className='hover:bg-background'>
                        <td className='relative border-b-4 border-cromboOrangeFade'>
                          <div className='flex flex-col items-center'>
                            {item?.product?.imageNames &&
                            item?.product?.imageNames[0] ? (
                              <img
                                className='w-full max-w-sm h-auto mx-auto'
                                src={item?.product.imageNames[0]}
                                alt={t("altText:firstImage")}
                              />
                            ) : (
                              <div>No image available</div>
                            )}
                            <div className='table-overlay'>
                              <span className='truncate text-sm'>
                                {item?.product?.title?.length > 20
                                  ? item?.product?.title.substring(0, 15) +
                                    "..."
                                  : item?.product?.title}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className='flex-col justify-start px-16 py-4 bg-background border-b-4 border-cromboOrangeFade text-slate-500'>
                          <div className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                              {t("admin:price")}
                            </div>
                            <div className='text-xl font-bold whitespace-nowrap'>
                              {item.product?.price ?? 0} kr.
                            </div>
                          </div>
                          <div className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 -mb-1 whitespace-nowrap'>
                              {t("admin:deliveryPriceLabel")}
                            </div>
                            <div className='text-xl font-bold whitespace-nowrap'>
                              {item.product?.deliveryPrice ?? 0} kr.
                            </div>
                          </div>

                          <div className='whitespace-pre-wrap mb-2'>
                            {Array.isArray(item.product?.addOns) &&
                            item.product?.addOns.length > 0 ? (
                              item.product?.addOns.map((addon, index) => (
                                <div
                                  key={index}
                                  className='flex items-center justify-between mb-1'>
                                  <div className='text-sm mr-2 -mb-1 whitespace-nowrap'>
                                    {addon.item}
                                  </div>
                                  <div className='text-xl font-bold whitespace-nowrap'>
                                    {addon.price ?? 0} kr.
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className='flex items-center justify-between mb-1'>
                                <div className='text-sm mr-2 -mb-1 whitespace-nowrap'>
                                  {t("admin:noAddons")}
                                </div>
                                <div className='text-xl font-bold whitespace-nowrap'>
                                  0 kr.
                                </div>
                              </div>
                            )}
                          </div>
                          <div className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                              {t("productPage:bonusDiscountLabel")}
                            </div>
                            <div className='text-xl font-bold whitespace-nowrap'>
                              {item.product?.bonusDiscount ?? 0} kr.
                            </div>
                          </div>
                          <div className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                              {t("admin:discount")}
                            </div>
                            <div className='text-xl font-bold whitespace-nowrap'>
                              {order.orderItems?.[0]?.extraTotal ?? 0} kr.
                            </div>
                          </div>
                          <div className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 font-bold text-slate-800 mb-0.5 whitespace-nowrap'>
                              {t("admin:grandTotalHeader")}
                            </div>
                            <div className='text-xl font-bold text-cromboOrange whitespace-nowrap'>
                              {order.orderItems?.[0]?.grandTotal ?? "N/A"} kr.
                            </div>
                          </div>
                        </td>

                        <td className='px-16 py-2 bg-background border-b-cromboOrangeFade border-4 border-r-2 border-white text-slate-500'>
                          <div className='p-4 bg-white rounded-md drop-shadow'>
                            <p className='mb-2 text-xs font-semibold text-gray-700'>
                              Fulfilled by: {order.courier}
                            </p>
                            <h4 className='text-gray-700 font-bold'>
                              {t("admin:slots")}
                            </h4>
                            <hr className='px-24 my-2 w-full'></hr>
                            {order.deliverySlots.map((slot, ind) => {
                              const dateTimeValue = `${moment(slot.date).format(
                                "DD/MM"
                              )} ${slot.time}`;
                              const uniqueId = `delivery-slot-${orderIndex}-${itemIndex}-${ind}`;
                              return (
                                <div
                                  key={ind}
                                  className='flex items-center mb-2'>
                                  <input
                                    type='checkbox'
                                    id={uniqueId}
                                    value={dateTimeValue}
                                    className='form-checkbox h-3 w-3 text-orange-600'
                                    onChange={(e) => {
                                      const selectedOptions = {
                                        ...selectedOption,
                                      };
                                      if (e.target.checked) {
                                        if (!selectedOptions[order._id]) {
                                          selectedOptions[order._id] = [];
                                        }
                                        selectedOptions[order._id].push({
                                          date: slot.date,
                                          time: slot.time,
                                        });
                                      } else {
                                        const index = selectedOptions[
                                          order._id
                                        ].findIndex(
                                          (opt) =>
                                            opt.date === slot.date &&
                                            opt.time === slot.time
                                        );
                                        if (index > -1) {
                                          selectedOptions[order._id].splice(
                                            index,
                                            1
                                          );
                                        }
                                        if (
                                          selectedOptions[order._id].length ===
                                          0
                                        ) {
                                          delete selectedOptions[order._id];
                                        }
                                      }
                                      setSelectedOption(selectedOptions);
                                    }}
                                  />
                                  <label
                                    htmlFor={uniqueId}
                                    className='ml-2 text-xl font-semibold text-orange-600'>
                                    {moment(slot.date).format("DD/MM")}{" "}
                                    <span className='text-sm text-gray-500'>
                                      {slot.time}
                                    </span>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </td>

                        <td
                          className='px-12 py-2 bg-background border-b-cromboOrangeFade border-4 border-r-2 border-white text-slate-500'
                          style={{ width: 200, whiteSpace: "normal" }}>
                          <div className='flex flex-col items-center'>
                            <img
                              className='w-8 h-8 mb-2'
                              src={locationMark}
                              alt={t("altText:location")}
                            />
                            <span className='text-center'>{`${order?.shippingAddress?.street}, ${order?.shippingAddress?.state}, ${order?.shippingAddress?.city}.`}</span>
                          </div>
                        </td>

                        <td className='px-4 py-2 bg-background border-b-cromboOrangeFade border-4 border-r-2 border-white text-slate-500'>
                          <div className='w-40 py-2 flex justify-center items-start'>
                            <span className='py-1'>
                              {order?.shippingAddress?.phone}
                            </span>
                          </div>
                        </td>

                        <td className='px-4 py-2 bg-background border-b-cromboOrangeFade border-4 border-r-2 border-white text-slate-500'>
                          <div className='flex flex-col justify-center items-center'>
                            <div className='flex flex-col justify-center items-center gap-2 p-8 text-center bg-white drop-shadow'>
                              <h3 className='font-bold text-lg text-black'>
                                {order.paymentMethod === "klarna"
                                  ? "Klarna"
                                  : t("admin:bankTransfer")}
                              </h3>
                              <p className='text-sm mb-3 -mt-2'>
                                {order.paymentMethod === "klarna"
                                  ? "Paid with Klarna"
                                  : t("admin:paymentReminder")}
                              </p>
                              <p className='text-xs font-semibold text-gray-700'>
                                Order ID: {order.orderNo}
                              </p>
                              <p className='mb-4 text-xs font-semibold text-gray-700'>
                                Invoice No.: {order.invoiceNo}
                              </p>
                              <button
                                onClick={() =>
                                  handleConfirmPayment(item?.product, order)
                                }
                                className='w-full py-1 px-1 mx-12 border-2 border-green-600 hover:border-green-500 hover:text-green-500 text-green-600 text-sm font-semibold rounded-lg'>
                                {t("admin:confirmOrderButton")}
                              </button>
                              <button
                                onClick={() =>
                                  handleDeclinePayment(item?.product, order)
                                }
                                className='mt-2 text-red-500 hover:text-red-400 text-xs font-semibold'>
                                {t("admin:cancelOrderButton")}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td className='px-4 py-2 bg-background border-b-cromboOrangeFade border-4 border-r-2 border-white text-slate-500'>
                          <div
                            className='flex items-center justify-center rounded-lg bg-white border-8 border-white text-red-500 hover:text-red-600 cursor-pointer drop-shadow'
                            onClick={() => openDeleteModal(order._id)}>
                            <HiOutlineTrash className='h-10 w-10' />
                            <h4 className='ml-2 font-bold'>
                              {t("admin:cancelOrderButton")}
                            </h4>
                          </div>
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          ) : (
            <div className='flex items-center justify-center w-full h-96 bg-white'>
              <h1 className='text-xl font-bold text-center text-gray-500'>
                {t("admin:noOrders")}
              </h1>
            </div>
          )}
        </div>
      </main>
      {showDeleteModal && (
        <DeleteWarningModal
          onConfirm={handleConfirmDelete}
          onClose={closeDeleteModal}
        />
      )}
    </div>
  );
}

export default ActiveOrders;
